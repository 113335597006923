/* eslint-disable react/prop-types */
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import JockeyBreadCrumb from "./JockeyBreadCrumb";
import DynamicRaceBreadcrumb from "./RaceBreadCrumb";
import TrainerBreadcrumb from "./TrainerBreadCrumb";
import HorseBreadcrumb from "./HorseBreadCrumb";

const routes = [
  { path: "/tracks/", breadcrumb: null },
  { path: "/horses/:hid", breadcrumb: HorseBreadcrumb },
  { path: "/jockeys/:jockeyId", breadcrumb: JockeyBreadCrumb },
  { path: "/trainers/:trainerId", breadcrumb: TrainerBreadcrumb },
  { path: "/past-form-guides/:mid", breadcrumb: DynamicRaceBreadcrumb },
  { path: "/current-form-guides/:mid", breadcrumb: DynamicRaceBreadcrumb },
  { path: "/past-form-guides/:mid/:raceId", breadcrumb: DynamicRaceBreadcrumb },
  { path: "/current-form-guides/:mid/:raceId", breadcrumb: DynamicRaceBreadcrumb },
];

const Breadcrumbs = (): ReactElement => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <div className="container breadcrumbs uppercase">
      <nav>
        {breadcrumbs.map(({ match: { pathname }, breadcrumb }) => {
          return (
            <Link key={pathname} to={pathname}>
              {breadcrumb}
            </Link>
          );
        })}
      </nav>
    </div>
  );
};
export default Breadcrumbs;
