import { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CurrentRaceLayout } from "./layout/CurrentRaceLayout";
import { PastRaceLayout } from "./layout/PastRaceLayout";
import RootLayout from "./layout/RootLayout";
import NotFound from "./pages/404";
import CurrentFormGuides from "./pages/current-race";
import CurrentRaceMeetingInfo from "./pages/current-race/meeting-info";
import CurrentRaceInfo from "./pages/current-race/race-info";
import Horses from "./pages/horses";
import HorseDetailsPage from "./pages/horses/hid";
import Jockeys from "./pages/jockeys";
import Jockey from "./pages/jockeys/jid";
import PastFormGuides from "./pages/past-race";
import PastRaceMeetingInfo from "./pages/past-race/meeting-info";
import PastRaceMeetingSummary from "./pages/past-race/meeting-summary";
import PastRaceInfo from "./pages/past-race/race-info";
import Tracks from "./pages/tracks";
import TrackDetailsPage from "./pages/tracks/trackId";
import Trainers from "./pages/trainers";
import Trainer from "./pages/trainers/tid";

function AppRoute(): ReactElement {
  return (
    <RootLayout>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Navigate to="/past-form-guides" replace />} />
        <Route path="/past-form-guides">
          <Route index element={<PastFormGuides />} />
          <Route path=":mid" element={<PastRaceLayout />}>
            <Route path="meeting-info" element={<PastRaceMeetingInfo />} />
            <Route path="meeting-summary" element={<PastRaceMeetingSummary />} />
            <Route path=":raceId" element={<PastRaceInfo />} />
          </Route>
        </Route>
        <Route path="/current-form-guides">
          <Route index element={<CurrentFormGuides />} />
          <Route path=":mid" element={<CurrentRaceLayout />}>
            <Route path="meeting-info" element={<CurrentRaceMeetingInfo />} />
            <Route path=":raceId" element={<CurrentRaceInfo />} />
          </Route>
        </Route>
        <Route path="/jockeys">
          <Route index element={<Jockeys />} />
          <Route path=":jid" element={<Jockey />} />
        </Route>
        <Route path="/trainers">
          <Route index element={<Trainers />} />
          <Route path=":tid" element={<Trainer />} />
        </Route>
        <Route path="/horses">
          <Route index element={<Horses />} />
          <Route path=":hid" element={<HorseDetailsPage />} />
        </Route>
        <Route path="/tracks">
          <Route index element={<Tracks />} />
          <Route path=":trackId" element={<TrackDetailsPage />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </RootLayout>
  );
}

export default AppRoute;
