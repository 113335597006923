/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import { BreadcrumbComponentType } from "use-react-router-breadcrumbs";
import { RootState } from "../../store";

const JockeyBreadCrumb: BreadcrumbComponentType<"jockeyId"> = ({ match: { params } }) => {
  const jockey = useSelector((state: RootState) => state.breadCrumbs.jockey);
  const name = params.jockeyId === jockey?.id ? jockey?.name : params.jockeyId;
  return <span>{name}</span>;
};

export default JockeyBreadCrumb;
