import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { OverlayLoader } from "../../components/Loader";
import RaceLeaderTable from "../../components/table/RaceLeaderTable";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { LeaderEntity } from "../../types";

const PastRaceMeetingSummary: React.FC = () => {
  const { mid = "" } = useParams();
  const URL = useMemo(() => getURL(`/meeting/summary/${mid}`), [mid]);
  const { data, error } = useSWR(URL, fetcher<{ data: LeaderEntity[] }>, {
    revalidateOnFocus: false,
  });
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);

  if (isLoading) return <OverlayLoader />;

  return <RaceLeaderTable leaders={data?.data} isLeaders={true} />;
};

export default PastRaceMeetingSummary;
