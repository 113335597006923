import { ReactElement } from "react";

interface TabButtonProps {
  btnText: string;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
}

const TabButton = ({
  btnText,
  onClick,
  isActive = false,
  className = "",
}: TabButtonProps): ReactElement => {
  return (
    <button
      className={`text-sm md:text-[15px] font-bold border rounded-md md:rounded-[10px] px-3 py-1.5 md:px-4 md:py-2 shadow-[0_4px_8px_rgba(64,95,157,.15)]
    ${
      isActive ? "bg-secondary text-white border-secondary" : "text-primary border-gray-300"
    } ${className}
  `}
      onClick={onClick}
    >
      {btnText}
    </button>
  );
};

export default TabButton;
