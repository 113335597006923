import { TableCell } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useMemo } from "react";
import useSWR from "swr";
import fetcher from "../../../lib/fetcher";
import getURL from "../../../lib/getEndPoint";
import { PeakRatingTableColumns } from "../../../lib/table.columns";
import { PeakRatingEntity } from "../../../types";

interface Props {
  hid?: string;
  setLoadingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const StickyColsLength = 5;
const StickyCols = PeakRatingTableColumns.slice(
  PeakRatingTableColumns.length - StickyColsLength,
  PeakRatingTableColumns.length
);
const NonStickyCols = PeakRatingTableColumns.slice(
  0,
  PeakRatingTableColumns.length - StickyColsLength
);

const TableHeadHeight = "!h-[81px]";

const PeakRatingsTable: React.FC<Props> = ({ hid, setLoadingCount }) => {
  const URL = getURL(`/horse/${hid}/peakRatings`);
  const { data, error } = useSWR(URL, fetcher<PeakRatingEntity>, {
    revalidateOnFocus: false,
  });

  const outerCols = useMemo(() => getPeakRatingKeys(data), [data]);
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);

  useEffect(() => {
    if (isLoading) setLoadingCount?.((prev: number) => prev + 1);
    if (!isLoading) setLoadingCount?.((prev: number) => prev - 1);
  }, [isLoading]);

  if (hid === undefined) return null;

  return (
    <>
      <div className="mt-4">
        <h3 className="text-secondary font-bold text-xl mb-3.5">Peak Ratings</h3>
        <div className={`flex mt-5 mb-12`}>
          {outerCols !== null && (
            <TableContainer
              className="max-w-max !border-transparent !shadow-none !rounded-2xl "
              component={Paper}
            >
              <Table className="border-0">
                <TableHead className={TableHeadHeight}>
                  <TableRow>
                    <TableCell className="!border-transparent !text-transparent">Header</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {outerCols?.map((col: string) => (
                    <TableRow key={col}>
                      <TableCell className="!border-transparent !font-semibold !capitalize !px-2.5">
                        {col?.replace("_", "-")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TableContainer
            component={Paper}
            className="shadow-none rounded-2xl border border-black-light whitespace-pre"
          >
            <Table>
              <TableHead className={`bg-white-f9 ${TableHeadHeight}`}>
                <TableRow>
                  {NonStickyCols.map((cols, index) => (
                    <TableCell
                      key={cols.key}
                      sx={{ fontWeight: "bold" }}
                      className={`whitespace-normal ${cols.className} bg-white-f9 !px-2.5`}
                      component="th"
                    >
                      {cols.header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {outerCols?.map((rowKey, idx) => (
                  <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {NonStickyCols.map((cols, _idx) => (
                      <TableCell
                        key={`${_idx}-${idx}-${rowKey}-${cols.key}`}
                        component="td"
                        sx={{
                          maxWidth: cols?.width ?? "max-content",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className={
                          cols.className != null ? `${cols.className} !bg-white !px-2.5` : "!px-2.5"
                        }
                      >
                        {cols.renderCell != null
                          ? cols.renderCell(data?.[rowKey][cols.key])
                          : data?.[rowKey][cols.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            className="shadow-none rounded-2xl border border-black-light whitespace-pre max-w-max"
          >
            <Table>
              <TableHead className="bg-white-f9">
                <TableRow className={TableHeadHeight}>
                  {StickyCols.map((cols, index) => (
                    <TableCell
                      key={cols.key}
                      sx={{ fontWeight: "bold" }}
                      className={`whitespace-normal ${cols.className} bg-white-f9 !px-2.5`}
                      component="th"
                    >
                      {cols.header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {outerCols?.map((rowKey, idx) => (
                  <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {StickyCols.map((cols, _idx) => (
                      <TableCell
                        key={`${_idx}-${idx}-${rowKey}-${cols.key}`}
                        component="td"
                        sx={{
                          maxWidth: cols?.width ?? "max-content",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className={
                          cols.className != null ? `${cols.className} !bg-white !px-2.5` : "!px-2.5"
                        }
                      >
                        {cols.renderCell != null
                          ? cols.renderCell(data?.[rowKey][cols.key])
                          : data?.[rowKey][cols.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default PeakRatingsTable;

const getPeakRatingKeys = (
  data: PeakRatingEntity | undefined
): Array<keyof PeakRatingEntity> | null => {
  // Get the keys of the PeakRatingEntity interface
  const keys = Object.keys(data ?? {}) as Array<keyof PeakRatingEntity>;
  if (data === undefined || keys.length === 0) {
    return null;
  }
  // Filter the keys based on the data available
  const availableKeys = keys.filter((key) => {
    return key in data && Object.keys(data[key]).length > 0;
  });
  if (availableKeys.length > 0) {
    return availableKeys;
  } else {
    return null;
  }
};
