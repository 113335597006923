import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { SettlingPositionEntity } from "../components/trainer-jockey/type";
import {
  CurrentRaceRatingEntity,
  CurrentRaceOtherInfoEntity,
  ExtraInformationEntity,
  HorseProfileFormEntity,
  IndividualTimeEntity,
  LeaderEntity,
  MeetingRacesEntity,
  OverViewEntity,
  PeakRatingRowEntity,
  RaceInfoEntity,
  SectionalTableEntity,
  TotalStakesEntity,
} from "../types";
import { ColumnDefinitionType } from "../types/table";
import { handleIfDataIsNull } from "./utils";
const renderCellSettlingPosition = (data: any): any => {
  try {
    const val = parseFloat(handleIfDataIsNull(data, null));
    if (Number.isNaN(val)) return <span></span>;
    return <span>{val.toFixed(2)}</span>;
  } catch {
    return <span></span>;
  }
};

type columns = "cols" | "right_cols";
// const stickycol = "border-l right-0 absolute top-auto w-[110px]";

const MeetingTableColumns: Array<ColumnDefinitionType<MeetingRacesEntity>> = [
  { key: "race_no", header: "Race Number" },
  { key: "start_time", header: "Start Time" },
  { key: "race_name", header: "Race Name" },
  { key: "distance", header: "Distance" },
  { key: "age", header: "Age" },
  { key: "class", header: "Class" },
  { key: "price_money", header: "Prize Money" },
  { key: "starters", header: "Starters" },
];

const RaceLeaderTableColumns: Array<ColumnDefinitionType<LeaderEntity>> = [
  { key: "to600", header: "TO600" },
  { key: "l800", header: "L800" },
  { key: "l600", header: "L600" },
  { key: "l400", header: "L400" },
  { key: "l200", header: "L200" },
  { key: "time", header: "Time" },
  { key: "to_600_all", header: "To600All" },
  { key: "last_600_all", header: "Last600All" },
  { key: "finish_all", header: "FinishAll" },
  { key: "to_600_class", header: "To600Class" },
  { key: "last_600_class", header: "Last600Class" },
  { key: "finish_class", header: "FinishClass" },
  { key: "rank", header: "Rank" },
];

const RaceLeadersTableColumns: Array<ColumnDefinitionType<LeaderEntity>> = [
  {
    key: "race_id",
    header: "Race No.",
  },
  ...RaceLeaderTableColumns,
];

const RaceOverviewKeys: Array<{ group: Array<ColumnDefinitionType<OverViewEntity>> }> = [
  {
    group: [
      { key: "age", header: "Age" },
      { key: "class", header: "Class" },
      { key: "sex_restrictions", header: "Sex Restriction" },
      { key: "rail_position", header: "Rail" },
      { key: "weather_status", header: "Weather" },
      { key: "surface_type", header: "Surface" },
      { key: "jockey_restrictions", header: "Jockey Restriction" },
    ],
  },
  {
    group: [
      { key: "limit_weight", header: "Limit Weight" },
      { key: "weight_types", header: "Weight Type" },
      { key: "tab_meeting", header: "TAB Meeting" },
      { key: "has_sectionals", header: "Has Sectionals" },
      { key: "peno", header: "Peno" },
      { key: "distance", header: "Dist" },
    ],
  },
  {
    group: [
      { key: "group_status", header: "Group Status" },
      { key: "resulted", header: "Resulted" },
      { key: "reqT600", header: "ReqT600" },
      { key: "reqL600", header: "ReqL600" },
      { key: "reqOne", header: "ReqOve" },
      { key: "baseRating", header: "Base Rating" },
    ],
  },
];

const TotalStakesKeys: Array<{ group: Array<keyof TotalStakesEntity> }> = [
  {
    group: ["1st", "2nd", "3rd"],
  },
  {
    group: ["4th", "5th", "6th"],
  },
];

const PastRaceInfoTableCols: {
  // eslint-disable-next-line no-unused-vars
  [K in columns]: Array<GridColDef & { field: keyof RaceInfoEntity }>;
} = {
  cols: [
    {
      field: "tabNo",
      filterable: false,
      headerName: "No.",
      sortable: false,
      width: 80,
    },
    {
      field: "position", // 'fp'
      filterable: false,
      type: "number",
      width: 60,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <span className="font-bold">{params.value}</span>;
      },
      renderHeader: (params) => {
        return <span className="font-bold">FP</span>;
      },
    },
    {
      field: "margin",
      filterable: false,
      headerName: "Margin",
      width: 80,
    },
    {
      field: "horsesName",
      filterable: false,
      headerName: "Horse",
      width: 150,
      renderCell: (params) => {
        return (
          <Link className="text-primary font-semibold" to={`/horses/${params.row?.horse_id}`}>
            {" "}
            {params.value}{" "}
          </Link>
        );
      },
    },
    {
      field: "barrier",
      filterable: false,
      headerName: "Bar.",
      type: "number",
      width: 70,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "trainer",
      filterable: false,
      headerName: "Trainer",
      width: 150,
      renderCell: (params) => {
        return (
          <Link className="text-primary font-semibold" to={`/trainers/${params.value?.id}`}>
            {params.value?.name}
          </Link>
        );
      },
    },
    {
      field: "jockey",
      filterable: false,
      headerName: "Jockey",
      width: 150,
      renderCell: (params) => {
        return (
          <Link className="text-primary font-semibold" to={`/jockeys/${params.value?.id}`}>
            {params.value?.name}
          </Link>
        );
      },
    },
    {
      field: "weight",
      filterable: false,
      headerName: "Wgt",
      width: 60,
    },
    {
      field: "cl",
      filterable: false,
      headerName: "Cl.",
      width: 60,
    },
    {
      field: "age",
      filterable: false,
      headerName: "Age",
      type: "number",
      width: 60,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "horseSex",
      filterable: false,
      headerName: "Sex",
      width: 60,
      renderCell: ({ value = "" }) => {
        if (value.length < 1) {
          return <span>{value}</span>;
        }
        let strArr = value.split(" ");
        strArr = strArr.map((str: string) => str[0]);
        return <span>{strArr.join("")}</span>;
      },
    },
    {
      field: "bfsp",
      filterable: false,
      headerName: "BFSP",
      width: 90,
    },
    {
      field: "in_run",
      filterable: false,
      headerName: "In-Run",
      width: 90,
    },
  ],
  right_cols: [
    {
      field: "esr",
      filterable: false,
      headerName: "ESR",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "lsr",
      filterable: false,
      headerName: "LSR",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "todo",
      filterable: false,
      headerName: "ToDo",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "rating",
      filterable: false,
      headerName: "Rating",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
  ],
};

const CurrentRaceInfoTableCols: {
  // eslint-disable-next-line no-unused-vars
  [K in columns]: Array<GridColDef & { field: keyof RaceInfoEntity }>;
} = {
  cols: [
    {
      field: "tabNo",
      filterable: false,
      headerName: "No.",
      width: 80,
    },
    {
      field: "horsesName",
      filterable: false,
      headerName: "Horse",
      width: 150,
      renderCell: (params) => {
        return (
          <Link className="text-primary font-semibold" to={`/horses/${params.row?.horse_id}`}>
            {" "}
            {params.value}
            {params.row?.scratching === 1 ? "*" : " "}
          </Link>
        );
      },
    },
    {
      field: "barrier",
      filterable: false,
      headerName: "Bar.",
      type: "number",
      width: 70,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "trainer",
      filterable: false,
      headerName: "Trainer",
      width: 150,
      renderCell: (params) => {
        return (
          <Link className="text-primary font-semibold" to={`/trainers/${params.value?.id}`}>
            {params.value?.name}
          </Link>
        );
      },
    },
    {
      field: "trRat",
      filterable: false,
      headerName: "Tr. Rat",
      width: 80,
    },
    {
      field: "jockey",
      filterable: false,
      headerName: "Jockey",
      width: 150,
      renderCell: (params) => {
        return (
          <Link className="text-primary font-semibold" to={`/jockeys/${params.value?.id}`}>
            {params.value?.name}
          </Link>
        );
      },
    },
    {
      field: "jkRat",
      filterable: false,
      headerName: "Jk. Rat",
      width: 80,
    },
    {
      field: "weight",
      filterable: false,
      headerName: "Wgt",
      width: 60,
    },
    {
      field: "cl",
      filterable: false,
      headerName: "Cl.",
      width: 60,
    },
    {
      field: "age",
      filterable: false,
      headerName: "Age",
      type: "number",
      width: 60,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "horseSex",
      filterable: false,
      headerName: "Sex",
      width: 60,
      renderCell: ({ value = "" }) => {
        if (value.length < 1) {
          return <span>{value}</span>;
        }
        let strArr = value.split(" ");
        strArr = strArr.map((str: string) => str[0]);
        return <span>{strArr.join("")}</span>;
      },
    },
    {
      field: "prep",
      filterable: false,
      headerName: "Prep",
      width: 80,
    },
    {
      field: "dslr",
      filterable: false,
      headerName: "DSLR",
      width: 80,
    },
  ],
  right_cols: [
    {
      field: "todo",
      filterable: false,
      headerName: "ToDo",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      width: 100,
    },
    {
      field: "priceModel",
      filterable: false,
      headerName: "Model Price",
      width: 100,
      renderCell: (params) => {
        return <>{params.row?.scratching === 1 ? "S" : params.value}</>;
      },
    },
  ],
};

const ExtraInformationTableCols: {
  // eslint-disable-next-line no-unused-vars
  [K in columns]: Array<GridColDef & { field: keyof ExtraInformationEntity }>;
} = {
  cols: [
    {
      field: "tabNo",
      filterable: false,
      headerName: "No.",
      sortable: false,
      width: 80,
    },
    {
      field: "position", // ? fp, position
      filterable: false,
      type: "number",
      width: 60,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <span className="font-bold">{params.value}</span>;
      },
      renderHeader: (params) => {
        return <span className="font-bold">FP</span>;
      },
    },
    {
      field: "margin",
      filterable: false,
      headerName: "Margin",
      width: 80,
    },
    {
      field: "horsesName",
      filterable: false,
      headerName: "Horse",
      width: 180,
      renderCell: (params) => {
        return (
          <Link className="text-primary font-semibold" to={`/horses/${params.row?.horse_id}`}>
            {" "}
            {params.value}{" "}
          </Link>
        );
      },
    },
    {
      field: "gearCharges",
      filterable: false,
      headerName: "Gear Changes",
      width: 180,
      align: "left",
      headerAlign: "left",
      renderCell: (params: any) => (
        <Tooltip title={params.value} arrow>
          <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{params.value}</p>
        </Tooltip>
      ),
    },
    {
      field: "stewardsReport",
      filterable: false,
      headerName: "Stewards Report",
      width: 220,
      renderCell: (params: any) => (
        <Tooltip title={params.value} arrow>
          <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{params.value}</p>
        </Tooltip>
      ),
    },
  ],
  right_cols: [
    {
      field: "esr",
      filterable: false,
      headerName: "ESR",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "lsr",
      filterable: false,
      headerName: "LSR",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "todo",
      filterable: false,
      headerName: "ToDo",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "rating",
      filterable: false,
      headerName: "Rating",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
  ],
};

const IndividualTimeTableCols: {
  // eslint-disable-next-line no-unused-vars
  [K in columns]: Array<GridColDef & { field: keyof IndividualTimeEntity }>;
} = {
  cols: [
    {
      field: "tabNo",
      filterable: false,
      headerName: "No.",
      sortable: false,
      width: 80,
    },
    {
      field: "position", // ?position | fp
      filterable: false,
      type: "number",
      width: 60,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <span className="font-bold">{params.value}</span>;
      },
      renderHeader: (params) => {
        return <span className="font-bold">FP</span>;
      },
    },
    {
      field: "margin",
      filterable: false,
      headerName: "Margin",
      width: 80,
    },
    {
      field: "hourseName",
      filterable: false,
      headerName: "Horse",
      width: 180,
      renderCell: (params) => {
        return (
          <Link className="text-primary font-semibold" to={`/horses/${params.row?.horse_id}`}>
            {" "}
            {params.value}{" "}
          </Link>
        );
      },
    },
    {
      field: "to600",
      filterable: false,
      headerName: "TO600",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "l800",
      filterable: false,
      headerName: "L800",
      width: 100,
    },
    {
      field: "l600",
      filterable: false,
      headerName: "L600",
      width: 100,
    },
    {
      field: "l400",
      filterable: false,
      headerName: "L400",
      width: 100,
    },
    {
      field: "l200",
      filterable: false,
      headerName: "L200",
      width: 100,
    },
    {
      field: "time",
      filterable: false,
      headerName: "Time",
      width: 100,
    },
    {
      field: "to_600_all",
      filterable: false,
      headerName: "To600All",
      width: 100,
    },
    {
      field: "last_600_all",
      filterable: false,
      headerName: "L600All",
      width: 100,
    },
    {
      field: "finish_all",
      filterable: false,
      headerName: "FinAll",
      width: 100,
    },
    {
      field: "to_600_class",
      filterable: false,
      headerName: "To600Cl",
      width: 100,
    },
    {
      field: "last_600_class",
      filterable: false,
      headerName: "L600Cl",
      width: 100,
    },
    {
      field: "finish_class",
      filterable: false,
      headerName: "FinCl",
      width: 100,
    },
  ],
  right_cols: [
    {
      field: "esr",
      filterable: false,
      headerName: "ESR",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "lsr",
      filterable: false,
      headerName: "LSR",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "todo",
      filterable: false,
      headerName: "ToDo",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
    {
      field: "rating",
      filterable: false,
      headerName: "Rating",
      renderCell: (params) => {
        const val = parseFloat(params.value);
        if (Number.isNaN(val)) return <span></span>;
        return <span>{val.toFixed(2)}</span>;
      },
      sortable: false,
      width: 80,
    },
  ],
};

const SettlingPositionCols: Array<ColumnDefinitionType<SettlingPositionEntity>> = [
  {
    key: "position",
    header: "Position",
  },
  {
    key: "settling_position",
    header: "Settling Position",
  },
  {
    key: "starters",
    header: "Starters",
  },
  {
    key: "wins",
    header: "Wins",
  },
  {
    key: "win_per",
    header: "Win%",
  },
  {
    key: "place",
    header: "Place",
  },
  {
    key: "place_per",
    header: "Place%",
  },
  {
    key: "ave_BFSP",
    header: "Ave BFSP",
    renderCell: renderCellSettlingPosition,
  },
  {
    key: "FSROI",
    header: "FSROI",
    renderCell: renderCellSettlingPosition,
  },
  {
    key: "PSROI",
    header: "PSROI",
    renderCell: renderCellSettlingPosition,
  },
  {
    key: "expectedWins",
    header: "ExW",
    // renderCell: renderCellSettlingPosition,
  },
  {
    key: "marketExp",
    header: "MarEx",
    // renderCell: renderCellSettlingPosition,
  },
];

const PeakRatingTableColumns: Array<ColumnDefinitionType<PeakRatingRowEntity>> = [
  { key: "posfin", header: "FP", width: 30 },
  { key: "margin", header: "Mar.", width: 30 },
  {
    key: "track",
    header: "Track",
    width: 50,
    renderCell: (value = "") => (
      <Tooltip title={value} arrow>
        <Link
          className="text-primary font-semibold"
          to={`/tracks/${value}`}
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {value?.slice(0, 4)}
        </Link>
      </Tooltip>
    ),
  },
  { key: "date", header: "Date", width: 110 },
  {
    key: "dslr",
    header: "DSLR",
    width: 100,
    renderCell: (value = "") => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  { key: "class", header: "Class", width: 120 },
  { key: "ageRestriction", header: "Age", width: 30 },
  {
    key: "sexRestriction",
    header: "Sex",
    width: 30,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value?.slice(0, 1)}</p>
      </Tooltip>
    ),
  },
  { key: "prizeMoney", header: "PM", width: 100 },
  { key: "dist", header: "Dist.", width: 100 },
  { key: "trackCondition", header: "TC", width: 100 },
  {
    key: "rail",
    header: "Rail",
    width: 80,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  {
    key: "jockey",
    header: "Jockey",
    width: 80,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  { key: "wgt", header: "Wgt.", width: 100 },
  { key: "claim", header: "Cl.", width: 30 },
  { key: "barrier", header: "Bar.", width: 30 },
  { key: "bfsp", header: "BFSP", width: 100 },
  { key: "run", header: "Run", width: 100 },
  { key: "otherRunners", header: "Other Runners", width: 100 },
  {
    key: "gearChanges",
    header: "Gear",
    width: 100,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  {
    key: "stewardsReport",
    header: "Stew",
    width: 100,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  { key: "raceTime", header: "Race Time", width: 100 },
  {
    key: "esr",
    header: "ESR",
    width: 100,
  },
  {
    key: "lsr",
    header: "LSR",
    width: 100,
  },
  {
    key: "todo",
    header: "ToDo",
    width: 100,
  },
  {
    key: "rating",
    header: "Rating",
    width: 100,
  },
  {
    key: "rank",
    header: "Rank",
    width: 100,
  },
];

const SectionalTableColumns: Array<{
  header: string | null;
  childCols: Array<ColumnDefinitionType<SectionalTableEntity>>;
}> = [
  {
    header: null,
    childCols: [
      {
        key: "date",
        header: "Date",
        width: 110,
      },
      {
        key: "venue",
        header: "Venue",
        width: 50,
        renderCell: (value = "") => (
          <Tooltip title={value} arrow>
            <Link
              className="text-primary font-semibold"
              to={`/tracks/${value}`}
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {value?.slice(0, 4)}
            </Link>
          </Tooltip>
        ),
      },
      { key: "barrier", header: "Bar.", width: 100 },
      { key: "dist", header: "Dist", width: 100 },
      { key: "margin", header: "Mar.", width: 100 },
      {
        key: "dslr",
        header: "DSLR",
        width: 100,
      },
    ],
  },
  {
    header: null,
    // ["To6", "8-6", "6-4", "4-2", "2-F"]
    childCols: [
      { key: "to6", header: "To6", width: 100 },
      { key: "8to6", header: "8-6", width: 100 },
      { key: "6to4", header: "6-4", width: 100 },
      { key: "4to2", header: "4-2", width: 100 },
      { key: "2toF", header: "2-F", width: 100 },
    ],
  },
  {
    header: "Finish",
    // ["P", " Time"]
    childCols: [
      { key: "position", header: "P", width: 100 },
      { key: "time", header: "Time", width: 100 },
    ],
  },
  {
    header: null,
    // ["L8", "L6", "L4", "L2"]
    childCols: [
      { key: "l8", header: "L8", width: 100 },
      { key: "l6", header: "L6", width: 100 },
      { key: "l4", header: "L4", width: 100 },
      { key: "l2", header: "L2", width: 100 },
    ],
  },
  {
    header: "Runner",
    // ["To6", "L6", "Fin"]
    childCols: [
      { key: "runner_to6", header: "To6", width: 100 },
      { key: "runner_l6", header: "L6", width: 100 },
      { key: "runner_fin", header: "Fin", width: 100 },
    ],
  },
  {
    header: "Race",
    childCols: [
      { key: "race_to6", header: "To6", width: 100 },
      { key: "race_l6", header: "L6", width: 100 },
      { key: "race_fin", header: "Fin", width: 100 },
    ],
  },
  {
    header: null,
    childCols: [
      {
        key: "esr",
        header: "ESR",
        className: "sticky border-l right-[240px] !max-w-[80px] !min-w-[80px]",
        renderCell: (value) => {
          const val = parseFloat(value);
          if (Number.isNaN(val)) return <span></span>;
          return <span>{val.toFixed(2)}</span>;
        },
      },
      {
        key: "lsr",
        header: "LSR",
        className: "sticky right-[160px] !max-w-[80px] !min-w-[80px]",
        renderCell: (value) => {
          const val = parseFloat(value);
          if (Number.isNaN(val)) return <span></span>;
          return <span>{val.toFixed(2)}</span>;
        },
      },
      {
        key: "todo",
        header: "ToDo",
        className: "sticky right-[80px] !max-w-[80px] !min-w-[80px]",
        renderCell: (value) => {
          const val = parseFloat(value);
          if (Number.isNaN(val)) return <span></span>;
          return <span>{val.toFixed(2)}</span>;
        },
      },
      {
        key: "rating",
        header: "Rating",
        className: "sticky right-0 !max-w-[80px] !min-w-[80px]",
        renderCell: (value) => {
          const val = parseFloat(value);
          if (Number.isNaN(val)) return <span></span>;
          return <span>{val.toFixed(2)}</span>;
        },
      },
    ],
  },
];

const FormTableColums: Array<ColumnDefinitionType<HorseProfileFormEntity>> = [
  { key: "posfin", header: "FP", width: 30 },
  { key: "margin", header: "Mar.", width: 30 },
  {
    key: "track",
    header: "Track",
    width: 50,
    renderCell: (value = "") => (
      <Tooltip title={value} arrow>
        <Link
          className="text-primary font-semibold"
          to={`/tracks/${value}`}
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {value?.slice(0, 4)}
        </Link>
      </Tooltip>
    ),
  },
  {
    key: "date",
    header: "Date",
    width: 110,
    renderCell: (value = "", row) => (
      <Link
        className="text-primary font-semibold"
        to={`/past-form-guides/${row?.meetingId}/${row.raceId}`}
      >
        {value}
      </Link>
    ),
  },
  {
    key: "dslr",
    header: "DSLR",
    width: 100,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  { key: "class", header: "Class", width: 120 },
  { key: "ageRestriction", header: "Age", width: 30 },
  {
    key: "sexRestriction",
    header: "Sex",
    width: 30,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value?.slice(0, 1)}</p>
      </Tooltip>
    ),
  },
  { key: "prizeMoney", header: "PM", width: 100 },
  { key: "dist", header: "Dist.", width: 100 },
  { key: "trackCondition", header: "TC", width: 100 },
  {
    key: "rail",
    header: "Rail",
    width: 80,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  {
    key: "jockey",
    header: "Jockey",
    width: 80,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  { key: "wgt", header: "Wgt.", width: 100 },
  { key: "claim", header: "Cl.", width: 30 },
  { key: "barrier", header: "Bar.", width: 30 },
  { key: "bfsp", header: "BFSP", width: 100 },
  { key: "run", header: "Run", width: 100 },
  { key: "otherRunners", header: "Other Runners", width: 100 },
  { key: "starters", header: "Starters", width: 80 },
  {
    key: "gearChanges",
    header: "Gear",
    width: 100,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  {
    key: "stewardsReport",
    header: "Stew",
    width: 100,
    renderCell: (value) => (
      <Tooltip title={value} arrow>
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value}</p>
      </Tooltip>
    ),
  },
  { key: "raceTime", header: "Race Time", width: 100 },
  {
    key: "esr",
    header: "ESR",
    width: 100,
  },
  {
    key: "lsr",
    header: "LSR",
    width: 100,
  },
  {
    key: "todo",
    header: "ToDo",
    width: 100,
  },
  {
    key: "rating",
    header: "Rating",
    width: 100,
  },
  {
    key: "rank",
    header: "Rank",
    width: 100,
  },
];

const CurrentRaceRatingTableColumns: Array<{
  header: string | null;
  childCols: Array<GridColDef & { field: keyof CurrentRaceRatingEntity }>;
}> = [
  {
    header: null,
    childCols: [
      {
        headerName: "No.",
        field: "tabNo",
        width: 50,
      },
      {
        field: "horse",
        headerName: "Horse",
        width: 180,
        cellClassName: "border-r",
        renderCell: (params) => {
          return (
            <Link className="text-primary font-semibold" to={`/horses/${params.value?.id}`}>
              {params.value?.name}
              {params.row?.scratching === 1 ? "*" : " "}
            </Link>
          );
        },
      },
    ],
  },
  {
    header: "Ratings",
    childCols: [
      {
        field: "last_start_rating",
        headerName: "LS",
        width: 80,
      },
      {
        field: "second_last_start_rating",
        headerName: "2LS",
        width: 80,
      },
      {
        field: "third_last_start_rating",
        headerName: "3LS",
        width: 80,
        cellClassName: "border-r",
      },
    ],
  },
  {
    header: "Rank",
    childCols: [
      {
        field: "last_start_rank",
        headerName: "LS",
        width: 80,
      },
      {
        field: "second_last_start_rank",
        headerName: "2LS",
        width: 80,
      },
      {
        field: "third_last_start_rank",
        headerName: "3LS",
        width: 80,
        cellClassName: "border-r",
      },
    ],
  },
  {
    header: "Market Expectation",
    childCols: [
      {
        field: "mar_exp_rating_ls",
        headerName: "LS",
        width: 80,
      },
      {
        field: "mar_exp_rating_2ls",
        headerName: "2LS",
        width: 80,
      },
      {
        field: "mar_exp_rating_3ls",
        headerName: "3LS",
        width: 80,
        cellClassName: "border-r",
      },
    ],
  },
  {
    header: "BFSP",
    childCols: [
      {
        field: "last_start_BFSP",
        headerName: "LS",
        width: 80,
        renderCell: (params) => {
          const val = parseFloat(params.value);
          if (Number.isNaN(val)) return <span></span>;
          return <span>{val.toFixed(2)}</span>;
        },
      },
      {
        field: "second_last_start_BFSP",
        headerName: "2LS",
        width: 80,
        renderCell: (params) => {
          const val = parseFloat(params.value);
          if (Number.isNaN(val)) return <span></span>;
          return <span>{val.toFixed(2)}</span>;
        },
      },
      {
        field: "third_last_start_BFSP",
        headerName: "3LS",
        width: 80,
        cellClassName: "border-r",
        renderCell: (params) => {
          const val = parseFloat(params.value);
          if (Number.isNaN(val)) return <span></span>;
          return <span>{val.toFixed(2)}</span>;
        },
      },
    ],
  },
  {
    header: "Strength of Races",
    childCols: [
      {
        field: "last_start_strength",
        headerName: "LS",
        width: 80,
      },
      {
        field: "second_last_start_strength",
        headerName: "2LS",
        width: 80,
      },
      {
        field: "third_last_start_strength",
        headerName: "3LS",
        width: 80,
        cellClassName: "border-r",
      },
    ],
  },
  {
    header: "FP",
    childCols: [
      {
        field: "last_start_fp",
        headerName: "LS",
        width: 70,
      },
      {
        field: "second_last_start_fp",
        headerName: "2LS",
        width: 70,
      },
      {
        field: "third_last_start_fp",
        headerName: "3LS",
        width: 70,
        cellClassName: "border-r",
      },
    ],
  },
  {
    header: "Margins",
    childCols: [
      {
        field: "last_start_margin",
        headerName: "LS",
        width: 80,
      },
      {
        field: "second_last_start_margin",
        headerName: "2LS",
        width: 80,
      },
      {
        field: "third_last_start_margin",
        headerName: "3LS",
        width: 80,
        cellClassName: "border-r",
      },
    ],
  },
  {
    header: null,
    childCols: [
      {
        field: "predicted_bfsp_to_rating_convert",
        headerName: "Predicted",
        width: 100,
      },
      {
        field: "price_model",
        headerName: "Price Model",
        width: 100,
        renderCell: (params) => {
          return <>{params.row?.scratching === 1 ? "S" : params.value}</>;
        },
      },
    ],
  },
];
const CurrentRaceOtherInfoTableColumns: Array<
  GridColDef & { field: keyof CurrentRaceOtherInfoEntity }
> = [
  { headerName: "No.", field: "tabNo", width: 70 },
  {
    field: "horse",
    headerName: "Horse",
    width: 180,
    cellClassName: "border-r",
    renderCell: (params) => {
      return (
        <Link className="text-primary font-semibold" to={`/horses/${params.value?.id}`}>
          {params.value?.name}
          {params.row?.scratching === 1 ? "*" : " "}
        </Link>
      );
    },
  },
  { field: "settling_position", headerName: "LS Settling Position", width: 180 },
  { field: "last_to_600_class", headerName: "LS To600class", width: 150 },
  { field: "last_600_class", headerName: "LS POS600", width: 100 },
  { field: "second_settling_position", headerName: "2LS Settling Position", width: 180 },
  { field: "second_last_to_600_class", headerName: "2LS To600class", width: 150 },
  { field: "second_last_600_class", headerName: "2LS POS600", width: 120 },
  { field: "third_settling_position", headerName: "3LS Settling Position", width: 180 },
  { field: "third_last_to_600_class", headerName: "3LS To600class", width: 150 },
  { field: "third_last_600_class", headerName: "3LS POS600", width: 100 },
  { field: "avg_settling_position", headerName: "Ave. Settling Position", width: 180 },
  { field: "pace_blend", headerName: "Pace Blend", width: 100 },
  { field: "pace_blend_rank", headerName: "Pace Blend Rank", width: 180 },
  {
    field: "toDo",
    filterable: false,
    headerName: "ToDo",
    renderCell: (params) => {
      const val = parseFloat(params.value);
      if (Number.isNaN(val)) return <span></span>;
      return <span>{val.toFixed(2)}</span>;
    },
    width: 80,
  },
  {
    field: "price_model",
    filterable: false,
    headerName: "Model Price",
    width: 100,
    renderCell: (params) => {
      return <>{params.row?.scratching === 1 ? "S" : params.value}</>;
    },
  },
];

export {
  CurrentRaceInfoTableCols,
  ExtraInformationTableCols,
  FormTableColums,
  IndividualTimeTableCols,
  MeetingTableColumns,
  PastRaceInfoTableCols,
  PeakRatingTableColumns,
  RaceLeadersTableColumns,
  RaceLeaderTableColumns,
  RaceOverviewKeys,
  SectionalTableColumns,
  SettlingPositionCols,
  TotalStakesKeys,
  CurrentRaceRatingTableColumns,
  CurrentRaceOtherInfoTableColumns,
};
