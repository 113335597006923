import { ReactElement, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import DynamicTableRender from "../../components/form-guides/DynamicTableRender";
import RaceOverview from "../../components/form-guides/RaceOverview";
import { OverlayLoader } from "../../components/Loader";
import RaceLeaderTable from "../../components/table/RaceLeaderTable";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { RaceOverViewEntity } from "../../types";
import NotFound from "../404";

const PastRaceInfo = (): ReactElement | null => {
  const { mid, raceId } = useParams();
  const URL = useMemo(() => _getURL(mid, raceId), [mid, raceId]);
  const { data, error } = useSWR(URL, fetcher<RaceOverViewEntity>, { revalidateOnFocus: false });
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);
  const leaders = data?.leader != null && data?.leader !== "" ? [data.leader] : undefined;
  if (mid === undefined || raceId === undefined) return <NotFound />;

  if (error != null) return null;

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      <RaceOverview data={data} />
      <RaceLeaderTable leaders={leaders} />
      {/* KEY : re-render on reaceID change */}
      <DynamicTableRender fromRoute="past" key={raceId} />
    </>
  );
};

export default PastRaceInfo;

const _getURL = (mid: string | undefined, raceId: string | undefined): string | null => {
  if (mid === undefined || raceId === undefined) return null;
  return getURL(`/race/${raceId}/meeting/${mid}/RaceOverView`);
};
