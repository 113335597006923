import { useMemo, useState } from "react";
import RaceCard from "../../components/RaceCard";
import TabButton from "../../components/TabButton";
import { MeetingListEntity, PaginationEnty } from "../../types";
import MeetingListFilter from "../../components/form-guides/MeetingListFilter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import { FilterObject, setFilter } from "../../store/meetingsFilter.slice";
import getURL from "../../lib/getEndPoint";
import PaginationRounded from "../../components/Pagination";
import { Loader } from "../../components/Loader";

export interface ResponseProps {
  meetingList: MeetingListEntity[];
  pagination: PaginationEnty;
}

const CurrentFormGuides: React.FC = () => {
  const [raceLocation] = useState("ALL");
  const filter = useSelector((state: RootState) => state.meetingsFilter.value.currentMeetings);
  const dispatch = useDispatch();
  const URL = useMemo(() => _getURL(filter), [JSON.stringify(filter)]);
  const { data, error } = useSWR(URL, fetcher<ResponseProps>, { revalidateOnFocus: false });
  const rows = data?.meetingList;
  const isLoading = rows === undefined && error === undefined;

  return (
    <div className={`container ${isLoading ? "pointer-events-none" : ""}`}>
      <div className="flex whitespace-nowrap my-5 py-3 space-x-3 md:space-x-5 text-primary overflow-x-auto text-sm font-bold">
        {["ALL", "AUSTRALIA", "NEW ZEALAND"].map((btnText) => (
          <TabButton key={btnText} btnText={btnText} isActive={btnText === raceLocation} />
        ))}
      </div>
      <MeetingListFilter isCurrent={true} />
      <div className="border border-black-light p-4 xl:p-10 rounded-xl mb-2.5">
        {renderElement(isLoading, error, rows)}
      </div>
      {isLoading ? null : (
        <PaginationRounded
          className="pagination pb-12 mt-4"
          count={data?.pagination?.page}
          onChange={(_, page) => {
            dispatch(
              setFilter({
                key: "currentMeetings",
                value: { ...filter, page },
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default CurrentFormGuides;

const _getURL = ({ limit, page, startDate, endDate, sortType, search }: FilterObject): string => {
  let url = getURL(`/meeting/future/list?limit=${limit}&page=${page}`);
  if ((sortType?.length ?? 0) > 0) {
    url = `${url}&sortType=${sortType}`;
  }
  if (startDate !== "" && endDate !== "") {
    url = `${url}&startDate=${startDate}&endDate=${endDate}`;
  }
  if (search !== "") url = `${url}&search=${search}`;
  return url;
};

const renderElement = (
  isLoading = false,
  error = undefined,
  rows: MeetingListEntity[] | undefined
): JSX.Element | null => {
  if (isLoading) return <Loader />;
  if (Boolean(error) || error !== undefined) {
    return <p className="text-center">Error...</p>;
  }
  if (rows != null && rows.length > 0) {
    return (
      <>
        {rows.map((meeting, index) => (
          <RaceCard
            key={meeting.meetingId}
            item={meeting}
            className={`md:flex justify-between items-start gap-5 xl:gap-12 ${
              rows.length === index + 1
                ? ""
                : "border-b border-black-light pb-5 xl:pb-10 mb-5 xl:mb-10"
            }`}
          />
        ))}
      </>
    );
  }
  return <p className="text-center">No Records...</p>;
};
