import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { handleIfDataIsNull } from "../../lib/utils";
import { ColumnDefinitionType } from "../../types/table";
import { DaysTabRow, TrainerTabEntity } from "./type";

const outerColsMap = (k: string): string | undefined => {
  const res = {
    "30": "Past 30 days",
    "60": "Past 60 days",
    "180": "Past 180 days",
    "360": "Past 360 days",
  }[k];

  if (res === undefined) return k.replace("_", "-");
  return res;
};

const renderCell = (data: any): any => {
  try {
    const val = parseFloat(handleIfDataIsNull(data, null));
    if (Number.isNaN(val)) return <span></span>;
    return <span>{val.toFixed(2)}</span>;
  } catch {
    return <span></span>;
  }
};

const tableCols: Array<ColumnDefinitionType<DaysTabRow>> = [
  { key: "runners", header: "Runners" },
  { key: "firstSt", header: "1st" },
  { key: "firstStPer", header: "1st %" },
  { key: "secondSt", header: "2nd" },
  { key: "secondStPer", header: "2nd%" },
  { key: "thirdSt", header: "3rd" },
  { key: "thirdStPer", header: "3rd %" },
  { key: "topThree", header: "Top 3" },
  { key: "topThreePer", header: "Top 3%" },
  { key: "unpl", header: "Unpl" },
  {
    key: "aveBFSP",
    header: "Ave BFSP",
    renderCell,
  },
  {
    key: "fsroi",
    header: "FSROI",
    renderCell,
  },
  {
    key: "psroi",
    header: "PSROI",
    renderCell,
  },
  {
    key: "expectedWins",
    header: "ExW",
    // renderCell
  },
  {
    key: "marketExp",
    header: "MarEx",
    // renderCell
  },
];

interface Props {
  data: TrainerTabEntity | undefined;
  outerCols: null | string[];
  currentTabKey: string;
  getTab: (arg0: string) => any;
}

const CommonTab: FC<Props> = ({ data, outerCols, getTab, currentTabKey }) => {
  const columns = [...tableCols];

  // runners or rides
  if (currentTabKey === "rides") {
    columns.splice(0, 1);
    // unpl = runners - top3 -> fixed by hook
    try {
      for (const [key, value] of Object.entries(data?.tab ?? {})) {
        if (data?.tab != null) {
          data.tab = { ...data.tab, [key]: { ...value, unpl: parseInt(key) - value?.topThree } };
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={`flex mt-5 mb-12`}>
      {outerCols !== null && (
        <TableContainer
          className="max-w-max !border-transparent !shadow-none !rounded-2xl "
          component={Paper}
        >
          <Table className="border-0">
            <TableHead>
              <TableRow>
                <TableCell className="!border-transparent !text-transparent">Header</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outerCols?.map((col: string) => (
                <TableRow key={col}>
                  <TableCell className="!border-transparent !font-semibold">
                    {outerColsMap(col)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TableContainer
        className="!shadow-none !rounded-2xl !border !border-black-light relative"
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="bg-white-f9 whitespace-nowrap">
            <TableRow>
              {columns.map((col) => (
                <TableCell sx={{ fontWeight: "bold" }} key={col.key}>
                  {col.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {outerCols !== null ? (
              outerCols.map((col) => (
                <TableRow
                  key={`row-${col}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map(({ key }) => (
                    <TableCell key={`col-${col}-${key}`}>{getTab(col)?.[key]}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow key={`row`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {columns.map(({ key, renderCell }) => {
                  if (renderCell != null) {
                    return <TableCell key={`col-${key}`}> {renderCell(data?.tab[key])} </TableCell>;
                  }
                  return <TableCell key={`col-${key}`}>{data?.tab[key]}</TableCell>;
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CommonTab;
