import { Box } from "@mui/material";
import { DataGrid, GridColumnGroupingModel } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { IGridSort } from "../../hooks/hook.types";
import useGridSort from "../../hooks/useGridSort";
import fetcher from "../../lib/fetcher";
import { CurrentRaceRatingTableColumns } from "../../lib/table.columns";
import { CurrentRaceRatingEntity, SortFieldMap } from "../../types";
import { OverlayLoader } from "../Loader";
import getURL from "../../lib/getEndPoint";

const InitialState: IGridSort = { field: "tabNo", sort: "asc" };

const columnGroupingModel: GridColumnGroupingModel = CurrentRaceRatingTableColumns.map((col) => {
  const randomId = Math.random() * 1000;
  return {
    groupId: col.header ?? randomId.toString(),
    children: col.childCols.map((childCol) => {
      return {
        field: childCol.field,
      };
    }),
    headerName: col.header ?? randomId.toString(),
    headerClassName: col?.header ?? "text-transparent",
    headerAlign: "center",
  };
});

const flattenedColumns = CurrentRaceRatingTableColumns.reduce((acc, col) => {
  //  @ts-expect-error
  return acc.concat(col.childCols);
}, []);

const rightTableColumns = flattenedColumns.splice(-2);
const rightTableColumnsGroup = columnGroupingModel.splice(-1);
const widthOfSecondTable = rightTableColumns?.reduce(
  (val, { width = 0 }) => val + parseInt(String(width)),
  5
);

const RaceRating: React.FC = () => {
  const { mid, raceId } = useParams();
  const [sortObj, handleSortModelChange, sortModel] = useGridSort(InitialState);

  const URL = useMemo(() => {
    return _getURL(mid, raceId, sortObj);
  }, [mid, raceId, sortObj]);

  const { data, error } = useSWR(URL, fetcher<CurrentRaceRatingEntity[]>, {
    revalidateOnFocus: false,
  });

  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);
  if (mid === undefined || raceId === undefined) return null;

  return (
    <Box className={"mb-10 flex w-full"}>
      {isLoading ? <OverlayLoader /> : null}
      <DataGrid
        experimentalFeatures={{ columnGrouping: true }}
        rows={data === undefined ? [] : data}
        showColumnRightBorder
        columns={flattenedColumns}
        classes={{ columnHeaders: "bg-white-f9" }}
        className="!rounded-r-none"
        loading={isLoading}
        error={error}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
        hideFooter
        autoHeight
        columnGroupingModel={columnGroupingModel}
        getRowId={(row: CurrentRaceRatingEntity) => `tableL-info-${row.tabNo}-${row.id}`}
      />
      <DataGrid
        experimentalFeatures={{ columnGrouping: true }}
        rows={data === undefined ? [] : data}
        columns={rightTableColumns}
        showColumnRightBorder
        hideFooter
        autoHeight
        loading={isLoading}
        error={error}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
        columnGroupingModel={rightTableColumnsGroup}
        classes={{
          columnHeaders: "bg-white-f9",
        }}
        style={{ maxWidth: widthOfSecondTable }}
        className="!border-l-0 !rounded-l-none"
        getRowId={(row: CurrentRaceRatingEntity) => `tableR-info-${row.tabNo}-${row.id}`}
      />
    </Box>
  );
};

export default RaceRating;

const _getURL = (
  mid: string | undefined,
  raceId: string | undefined,
  sortObj: IGridSort
): string => {
  let url = getURL(`/race/future/${raceId}/meeting/${mid}/ratingInformation`);
  if (sortObj.field.length > 0 && sortObj.sort.length > 0) {
    const field = sortFieldMap()[sortObj.field];
    if (field !== undefined) url = `${url}?sortFiled=${field}&sortType=${sortObj.sort}`;
  }
  return url;
};

const sortFieldMap = (): { [key: string]: string } => {
  const obj: SortFieldMap<CurrentRaceRatingEntity> = {
    horse: "horses.name",
    tabNo: "future_race_sectional_benchmark.csv_tab_no",
    last_start_rating: "fdg.last_start_rating",
    second_last_start_rating: "fdg.second_last_start_rating",
    third_last_start_rating: "fdg.third_last_start_rating",
    last_start_rank: "fdg.last_start_rank",
    second_last_start_rank: "fdg.second_last_start_rank",
    third_last_start_rank: "fdg.third_last_start_rank",
    mar_exp_rating_ls: "fdg.mar_exp_rating_ls",
    mar_exp_rating_2ls: "fdg.mar_exp_rating_2ls",
    mar_exp_rating_3ls: "fdg.mar_exp_rating_3ls",
    last_start_BFSP: "fdg.last_start_BFSP",
    second_last_start_BFSP: "fdg.second_last_start_BFSP",
    third_last_start_BFSP: "fdg.third_last_start_BFSP",
    last_start_strength: "fdg.last_start_strength",
    second_last_start_strength: "fdg.second_last_start_strength",
    third_last_start_strength: "fdg.third_last_start_strength",
    last_start_fp: "fdg.last_start_fp",
    second_last_start_fp: "fdg.second_last_start_fp",
    third_last_start_fp: "fdg.third_last_start_fp",
    last_start_margin: "fdg.last_start_margin",
    second_last_start_margin: "fdg.second_last_start_margin",
    third_last_start_margin: "fdg.third_last_start_margin",
    predicted_bfsp_to_rating_convert: "fdg.predicted_bfsp_to_rating_convert",
    price_model: "fdg.price_model",
  };
  return obj;
};
