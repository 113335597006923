import { FC } from "react";

interface IProps {
  className?: string;
}

const Loader: FC<IProps> = ({ className }) => {
  return (
    <div
      className={`container w-full flex items-center justify-center h-[400px] max-h-screen ${className}`}
    >
      <img src="/loading.gif" alt="loading..." className="w-32 h-auto" />
    </div>
  );
};

const OverlayLoader: FC<IProps> = ({ className }) => {
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-gray-800 backdrop-blur-sm bg-opacity-50 ${className}`}
    >
      <div className="bg-white w-[400px] h-[150px] rounded-xl flex flex-col shadow-md justify-center items-center">
        {/* <div className="w-16 h-16 border-4 border-secondary border-dashed rounded-full animate-spin"></div> */}
        <img src="/loading.gif" alt="loading..." className="w-32 h-auto" />
      </div>
    </div>
  );
};

export { Loader, OverlayLoader };
