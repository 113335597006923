import { useParams } from "react-router-dom";
import MeetingInfoComp from "../../components/form-guides/MeetingInfo";
import { MeetingTableColumns } from "../../lib/table.columns";
import { useMemo } from "react";
import getURL from "../../lib/getEndPoint";
import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import { MeetingEntity } from "../../types";

const CurrentRaceMeetingInfo: React.FC = () => {
  const cols = MeetingTableColumns.filter((col) => col.key !== "age");
  const { mid = "" } = useParams();
  const URL = useMemo(() => getURL(`/meeting/future/detail/${mid}`), [mid]);
  const { data, error } = useSWR(URL, fetcher<MeetingEntity>);
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);
  return <MeetingInfoComp data={data} columns={cols} isLoading={isLoading} baseURL="/current-form-guides" />;
};

export default CurrentRaceMeetingInfo;
