import { ReactElement } from "react";

interface ChipProps {
  chipText: string;
  className?: string;
}

const Chip = ({ chipText = "", className = "" }: ChipProps): ReactElement => {
  return (
    <span
      className={`bg-primary rounded-[25px] md:rounded-[50px] py-1 px-4 text-white text-sm md:text-base ${className}`}
    >
      {chipText}
    </span>
  );
};

export default Chip;
