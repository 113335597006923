import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactElement } from "react";
import { MeetingEntity, MeetingRacesEntity } from "../../types";
import { ColumnDefinitionType } from "../../types/table";
import { Link } from "react-router-dom";

interface MeetingInfoCompPorps {
  data: MeetingEntity | undefined;
  columns: Array<ColumnDefinitionType<MeetingRacesEntity>>;
  isLoading?: boolean;
  baseURL?: string;
}

const MeetingInfoComp = ({
  data,
  columns,
  isLoading,
  baseURL,
}: MeetingInfoCompPorps): ReactElement => {
  const races = data?.races ?? [];

  return (
    <TableContainer
      className="shadow-none rounded-2xl mb-12 border border-black-light"
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-white-f9">
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.key} sx={{ fontWeight: "bold" }}>
                {col.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ?? false ? <p className="w-full text-center p-2">Loading...</p> : null}
          {races?.map((row) => (
            <TableRow key={row.raceId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              {columns.map((col) => (
                <TableCell
                  key={`cell-${row.raceId}-${col.key}`}
                  className={col.key === "race_name" ? "!text-primary font-semibold" : ""}
                >
                  {col.key === "race_name" ? (
                    <Link to={`${baseURL}/${data?.meetingId}/${row.raceId}`}>{row[col.key]}</Link>
                  ) : (
                    row[col.key]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MeetingInfoComp;
