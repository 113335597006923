import { ReactElement } from "react";
import { TabOptionType, CurrentTabType } from "../lib/constant.data";

interface Props {
  options: TabOptionType;
  currentTab: CurrentTabType;
  changeTab: (arg0: CurrentTabType) => void;
}

export default function Tab({ options, currentTab, changeTab }: Props): ReactElement {
  return (
    <div className="flex items-center border-b border-dashed mb-4 px-2">
      {options.map((item) => (
        <div
          key={item.key}
          onClick={() => changeTab(item)}
          className={`text-center px-6 py-2.5 cursor-pointer bg-transparent border-[#E3E5E6] text-[#5E6366] mx-0.5 ${
            item.key === currentTab.key
              ? "border border-solid  border-b-white rounded-[4px_4px_0px_0px] -my-[1px]"
              : ""
          }`}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
}
