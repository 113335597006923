import { TableCell } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import fetcher from "../../../lib/fetcher";
import getURL from "../../../lib/getEndPoint";
import { FormTableColums } from "../../../lib/table.columns";
import { HorseProfileFormEntity } from "../../../types";

interface Props {
  hid?: string;
  setLoadingCount?: React.Dispatch<React.SetStateAction<number>>;
}
const stickycolLength = 5;
const TableHeadHeight = 81;
const TableHeadHeightClass = `!h-[${TableHeadHeight}px]`;
const itemsPerPage = 10;

const HorseProfileFormTable: React.FC<Props> = ({ hid, setLoadingCount }) => {
  const URL = getURL(`/horse/${hid}/form`);
  const { data, error } = useSWR(URL, fetcher<HorseProfileFormEntity[]>, {
    revalidateOnFocus: false,
  });

  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);

  useEffect(() => {
    if (isLoading) setLoadingCount?.((prev: number) => prev + 1);
    if (!isLoading) setLoadingCount?.((prev: number) => prev - 1);
  }, [isLoading]);

  if (hid === undefined) return null;

  return (
    <>
      <div className="mt-4">
        <h3 className="text-secondary font-bold text-xl mb-3.5">Form</h3>
        <RenderTable data={data ?? []} />
      </div>
    </>
  );
};

export default HorseProfileFormTable;

const RenderTable: React.FC<{ data: HorseProfileFormEntity[] }> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [prevScrollTop, setPrevScrollTop] = useState(0);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const { scrollLeft, scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (isLoading) return;
    if (scrollTop < prevScrollTop && scrollLeft > 0) return;

    if (
      scrollTop + clientHeight >= scrollHeight &&
      currentPage < Math.ceil(data.length / itemsPerPage)
    ) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentPage((prevState) => prevState + 1);
        setIsLoading(false);
      }, 500);
    }
    setPrevScrollTop(scrollTop);
  };

  const tableRows = useMemo(() => {
    const start = 0;
    const end = Math.min(currentPage * itemsPerPage, data.length);
    return data.slice(start, end + 1);
  }, [data, currentPage]);

  return (
    <div className="relative mt-5 mb-14">
      <TableContainer
        component={Paper}
        onScroll={handleScroll}
        style={{
          overflowY : 'auto',
          height:
            TableHeadHeight +
            (tableRows.length > itemsPerPage ? itemsPerPage : tableRows.length) * 54,
        }}
        className="shadow-none w-full rounded-2xl border border-black-light whitespace-pre"
      >
        <Table className="!border-separate">
          <TableHead className="bg-white-f9 sticky top-0 z-10 shadow-sm">
            <TableRow className={TableHeadHeightClass}>
              {FormTableColums?.map((cols, _ind) => {
                const isSticky = _ind + 1 > FormTableColums?.length - stickycolLength;
                const stickeyPos = isSticky ? FormTableColums?.length - _ind : 0;
                const isFirstSticky = stickeyPos === stickycolLength;

                return (
                  <TableCell
                    key={cols.key}
                    sx={{ fontWeight: "bold" }}
                    style={
                      isSticky
                        ? {
                            position: isSticky ? "sticky" : "relative",
                            right: isSticky ? (stickeyPos - 1) * 60 : undefined,
                            minWidth: 60,
                            maxWidth: 60,
                            borderLeftWidth: isFirstSticky ? 1 : undefined,
                          }
                        : undefined
                    }
                    className={`whitespace-normal ${cols.className ?? ""} bg-white-f9 !px-2.5`}
                    component="th"
                  >
                    {cols.header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows?.map((row, idx) =>
              row.heading?.length != null ? (
                <TableRow key={idx} className="h-[54px]">
                  <TableCell
                    align="center"
                    component="td"
                    colSpan={FormTableColums?.length}
                    className="!bg-[rgba(255,246,232,0.8)] !font-bold !text-[#ff9900] !px-2.5"
                  >
                    {row.heading}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={idx}
                  className="h-[54px]"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {FormTableColums?.map((cols, _idx) => {
                    const isSticky = _idx + 1 > FormTableColums?.length - stickycolLength;
                    const stickeyPos = isSticky ? FormTableColums?.length - _idx : 0;
                    const isFirstSticky = stickeyPos === stickycolLength;
                    return (
                      <TableCell
                        key={`${row[cols.key]}-${cols.key}-${_idx}`}
                        component="td"
                        sx={{
                          maxWidth: cols?.width ?? "max-content",
                          minWidth: "max-content",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        style={
                          isSticky
                            ? {
                                position: isSticky ? "sticky" : "relative",
                                right: isSticky ? (stickeyPos - 1) * 60 : undefined,
                                minWidth: 60,
                                maxWidth: 60,
                                backgroundColor: "white",
                                borderLeftWidth: isFirstSticky ? 1 : undefined,
                              }
                            : undefined
                        }
                        className={`!border-black-light ${
                          cols.className != null
                            ? `${cols.className} !bg-white !px-2.5`
                            : `!px-2.5 `
                        }`}
                      >
                        {cols.renderCell != null ? cols.renderCell(row[cols.key], row) : row[cols.key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && (
        <div className="absolute top-full translate-y-3 left-0 right-0 z-50 text-center  flex justify-center">
          <p className="py-2 px-8 rounded-full bg-gray-200">Loading...</p>
        </div>
      )}
    </div>
  );
};
