import { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../lib/date/formatDate";
import { MeetingListEntity } from "../types";
import RaceItem from "./RaceItem";

interface RaceCardProps {
  className: string;
  item: MeetingListEntity;
}

export default function RaceCard({
  className,
  item: {
    meetingId,
    meeting_date,
    peno,
    rail,
    surface,
    track_abbrev,
    track_name,
    races,
    state = "",
    categories = "",
    weather_status,
  },
}: RaceCardProps): ReactElement {
  const { pathname: currentPath } = useLocation();
  return (
    <div className={className}>
      <div className="grow lg:flex-none lg:w-[320px] xl:w-[500px] mb-7 md:mb-0">
        <div className="flex items-center gap-2.5 mb-6">
          <h3>
            <Link
              to={`/tracks/${track_name}`}
              className="text-lg xl:text-2xl text-secondary font-bold hover:text-primary"
            >
              {track_name} | {state?.length > 0 ? state : "NA"}
            </Link>
          </h3>
          <p className="bg-primary rounded-full py-1.5 px-2.5 xl:py-1.5 xl:px-4 inline-block text-white text-sm xl:text-base !leading-none">
            {track_abbrev}
          </p>
          <p className="bg-[#bec8d0] rounded-full py-1.5 px-2.5 xl:py-1.5 xl:px-4 inline-block text-white text-sm xl:text-base !leading-none">
            {categories?.length > 0 ? categories : "NA"}
          </p>
        </div>
        <div className="grid grid-cols-auto2 xl:grid-cols-2 gap-2 mb-7 md:mb-10 xl:mb-14 text-[15px]">
          <p className="font-normal">
            <strong>Date:</strong> {formatDate(meeting_date)}
          </p>
          <p className="font-normal">
            <strong>Rail:</strong> {rail}
          </p>
          <p className="font-normal">
            <strong>Weather:</strong> {weather_status}
          </p>
          <p className="font-normal">
            <strong>Surface:</strong> {surface}
          </p>
          <p className="font-normal">
            <strong>Peno:</strong> {peno}
          </p>
        </div>
        <Link
          to={`${currentPath}/${meetingId}/meeting-info`}
          className="inline-flex items-center gap-2 text-primary border border-black-light rounded-lg px-4 py-2.5 md:px-5 md:py-4 shadow-[0_4px_8px_rgba(64,95,157,0.15)] leading-none text-sm md:text-[15px] font-bold hover:bg-secondary hover:text-white"
        >
          MEETING INFORMATION{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="3"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
      <div className="grow">
        <ul className="grid grid-cols-auto2 lg:grid-cols-2 gap-4 lg:gap-8">
          {races != null && races.length > 0
            ? races.map((item, index) => (
                <RaceItem
                  item={item}
                  key={index}
                  href={`${currentPath}/${meetingId}/${item.raceId}`}
                />
              ))
            : null}
        </ul>
      </div>
    </div>
  );
}
