import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SlugDetail {
  id: number | string;
  name: string;
}

interface BreadCrumbSlice {
  trainer: SlugDetail | null;
  jockey: SlugDetail | null;
  horse: SlugDetail | null;
  raceInfo: { meeting: SlugDetail | null; race: SlugDetail | null };
}

const initialState: BreadCrumbSlice = {
  trainer: null,
  horse: null,
  jockey: null,
  raceInfo: { meeting: null, race: null },
};

export const breadCrumbSlice = createSlice({
  name: "breadCrumbs",
  initialState,
  reducers: {
    setTrainerSlugName: (state, action: PayloadAction<SlugDetail>) => {
      state.trainer = action.payload;
    },
    setJockeySlugName: (state, action: PayloadAction<SlugDetail>) => {
      state.jockey = action.payload;
    },
    setHorseSlugName: (state, action: PayloadAction<SlugDetail>) => {
      state.horse = action.payload;
    },
    setRaceInfoMeetingObj: (state, action: PayloadAction<SlugDetail>) => {
      state.raceInfo.meeting = action.payload;
    },
    setRaceInfoRaceObj: (state, action: PayloadAction<SlugDetail>) => {
      state.raceInfo.race = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTrainerSlugName,
  setHorseSlugName,
  setRaceInfoMeetingObj,
  setRaceInfoRaceObj,
  setJockeySlugName,
} = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;
