import React, { KeyboardEvent, useEffect, useState } from "react";
import useSWR from "swr";
import useDebounce from "../../hooks/useDebounce";
import getURL from "../../lib/getEndPoint";
import fetcher from "../../lib/fetcher";
import { TrackFilterType } from "../../pages/tracks/trackId";
import Title from "../Title";

const initial_state = {
  startDate: "",
  endDate: "",
  startDistance: "",
  endDistance: "",
  rail: ""
};

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

interface Props {
  applyFilter: (filterState: TrackFilterType) => void;
}

const TrackFilter: React.FC<Props> = ({ applyFilter }) => {
  const [filter, setfilter] = useState(initial_state);
  const debouncedValue = useDebounce<typeof initial_state>(filter, 500);

  const URL = getURL(`/track/railsLookup`);
  const { data: railData, error } = useSWR(URL, fetcher <[]>, { revalidateOnFocus: false });

  if (error !== undefined) return null;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key.match(/^[0-9]+$/) === null && !["Backspace", "Enter"].includes(event.key)) {
      event.preventDefault();
    }
  };

  // debounce
  useEffect(() => {
    applyFilter(filter);
  }, [debouncedValue]);

  return (
    <div className="flex justify-between items-center my-6 text-sm flex-wrap md:flex-nowrap">
      <Title titleText="Settling Position" className="!text-xl text-secondary" />
      <div className="grow md:flex-none flex items-center gap-2.5 font-normal mt-5 md:mt-0 flex-wrap md:flex-nowrap">
        <div className="grow md:flex-none flex items-center gap-2.5">
          <p className="whitespace-nowrap font-semibold hidden md:block">Date Range:</p>
          <input
            type="date"
            className="form-input"
            defaultValue={filter.startDate}
            max={yesterday.toISOString().split("T")[0]}
            onChange={({ target }) => setfilter((prev) => ({ ...prev, startDate: target.value }))}
          />
          <p className="shrink-0">to</p>
          <input
            type="date"
            defaultValue={filter.endDate}
            className="form-input"
            max={new Date().toISOString().split("T")[0]}
            onChange={({ target }) => setfilter((prev) => ({ ...prev, endDate: target.value }))}
          />
        </div>
        <div className="grow md:flex-none flex items-center gap-2.5">
          <p className="whitespace-nowrap font-semibold hidden md:block">Distance Range:</p>
          <input
            type="number"
            className="form-input max-w-[120px]"
            onKeyDown={handleKeyDown}
            onChange={({ target }) =>
              setfilter((prev) => ({ ...prev, startDistance: target.value }))
            }
          />
          <p className="shrink-0">to</p>
          <input
            type="number"
            className="form-input max-w-[120px]"
            onKeyDown={handleKeyDown}
            onChange={({ target }) => setfilter((prev) => ({ ...prev, endDistance: target.value }))}
          />
        </div>
        <div className="grow md:flex-none flex items-center justify-center gap-2.5">
          <p className="whitespace-nowrap font-semibold">Rail:</p>
          <select name="table" className="form-select w-32 md:w-36"
          onChange={({ target }) => setfilter((prev) => ({ ...prev, rail: target.value }))}>
            <>
              <option></option>
              { railData !== undefined ? (railData.map((railLabel: string) =>
                  <option key={railLabel}>{railLabel}</option>
                )) : (
                  <option></option>
                ) 
              };
            </>  
          </select>  
        </div>
      </div>
    </div>
  );
};

export default TrackFilter;
