/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import { BreadcrumbComponentType } from "use-react-router-breadcrumbs";
import { RootState } from "../../store";

const RaceBreadCrumb: BreadcrumbComponentType = ({ match }) => {
  const { mid, raceId } = match.params;
  const raceInfo = useSelector((state: RootState) => state.breadCrumbs.raceInfo);

  if (raceId === undefined) {
    const name = mid === raceInfo.meeting?.id ? raceInfo.meeting?.name : mid;
    return <span>{name}</span>;
  }
  const name = raceId === raceInfo.race?.id ? raceInfo.race?.name : raceId;
  return <span>{name}</span>;
};
export default RaceBreadCrumb;
