import React from "react";
import Pagination, { PaginationProps } from "@mui/material/Pagination";

const PaginationRounded: React.FC<PaginationProps> = ({ className, ...rest }) => {
  return (
    <div className={className}>
      <Pagination {...rest} variant="outlined" shape="rounded" />
    </div>
  );
};

export default PaginationRounded;
