import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import MeetingInfoComp from "../../components/form-guides/MeetingInfo";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { MeetingTableColumns } from "../../lib/table.columns";
import { MeetingEntity } from "../../types";

const PastRaceMeetingInfo: React.FC = () => {
  const { mid = "" } = useParams();
  const URL = useMemo(() => getURL(`/meeting/detail/${mid}`), [mid]);
  const { data, error } = useSWR(URL, fetcher<MeetingEntity>);
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);
  return <MeetingInfoComp data={data} columns={MeetingTableColumns} isLoading={isLoading} baseURL="/past-form-guides" />;
};

export default PastRaceMeetingInfo;
