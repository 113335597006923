import { SpeedMapRow } from "./type";

/* eslint-disable @typescript-eslint/explicit-function-return-type */

function createFormData(
  post: string,
  mar: string,
  venue: string,
  date: string,
  race: string,
  formClass: string,
  age: string,
  sex: string,
  prize: string,
  dist: number,
  con: string,
  rail: string,
  jockey: { name: string; id: number },
  wgt: number,
  bar: number,
  bfsp: number,
  run: string,
  runners: string,
  raceTime: string,
  tvss?: undefined,
  rating?: undefined
) {
  return {
    post,
    mar,
    venue,
    date,
    race,
    formClass,
    age,
    sex,
    prize,
    dist,
    con,
    rail,
    jockey,
    wgt,
    bar,
    bfsp,
    run,
    runners,
    raceTime,
    rating,
    tvss,
  };
}

export const horseDetailsFormRows = [
  createFormData(
    "7-9",
    "5.4",
    "NCLE",
    "14/05/22",
    "Newcastle Skin",
    "Maiden",
    "N/A",
    "C,H&G",
    "$35,000",
    1200,
    "HB",
    "5.0",
    { name: "A Adkins", id: 121 },
    60,
    3,
    23.61,
    "5-5-4",
    "1-Backrower",
    "01:17.31"
  ),
  createFormData(
    "7-9",
    "5.4",
    "NCLE",
    "14/05/22",
    "Newcastle Skin",
    "Maiden",
    "N/A",
    "C,H&G",
    "$35,000",
    1200,
    "HB",
    "5.0",
    { name: "A Adkins", id: 121 },
    60,
    3,
    23.61,
    "5-5-4",
    "1-Backrower",
    "01:17.31"
  ),
  createFormData(
    "7-9",
    "5.4",
    "NCLE",
    "14/05/22",
    "Newcastle Skin",
    "Maiden",
    "N/A",
    "C,H&G",
    "$35,000",
    1200,
    "HB",
    "5.0",
    { name: "A Adkins", id: 121 },
    60,
    3,
    23.61,
    "5-5-4",
    "1-Backrower",
    "01:17.31"
  ),
];

export const getCurrentRaceInfoData: {
  speedMapData: SpeedMapRow[];
  raceInfoRow: any[];
} = {
  speedMapData: [
    {
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: {
        id: 34,
        src: "/image_3.png",
        name: "6 - Turf Edition",
        price: "$11.68",
      },
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
    {
      col1: "",
      col2: {
        id: 15,
        src: "/image_1.png",
        name: "8 - Runinscared",
        price: "$14.82",
      },
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
    {
      col1: {
        id: 9,
        src: "/image_2.png",
        name: "15 - Super Rooster",
        price: "$158.64",
      },
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
  ],
  raceInfoRow: [
    {
      no: 9,
      horsesName: "Lighting Run",
      barrier: 8,
      trainer: { id: 31, name: "Ms H Harding" },
      jockey: { id: 97, name: "Troy Turner" },
      weight: 55,
      cl: "0",
      age: 2,
      horseSex: "M",
      dslr: 23,
      gear_changes: "N/A",
      todo: "...",
      model_rating: "...",
      model_price: "...",
    },
    {
      no: 7,
      horsesName: "Lighting Run",
      barrier: 5,
      trainer: { id: 75, name: "Ms H Harding" },
      jockey: { id: 23, name: "Troy Turner" },
      weight: 55,
      cl: "0",
      age: 2,
      horseSex: "M",
      dslr: 23,
      gear_changes: "N/A",
      todo: "...",
      model_rating: "...",
      model_price: "...",
    },
    {
      no: 3,
      horsesName: "Lighting Run",
      barrier: 7,
      trainer: { id: 48, name: "Ms H Harding" },
      jockey: { id: 83, name: "Troy Turner" },
      weight: 55,
      cl: "0",
      age: 2,
      horseSex: "M",
      dslr: 23,
      gear_changes: "N/A",
      todo: "...",
      model_rating: "...",
      model_price: "...",
    },
  ],
};
