import React from "react";
import debounce from "../../lib/debounce";
import { TSortType, setFilter } from "../../store/meetingsFilter.slice";
import TitleAndSearch from "../TitleAndSearch";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";

const MeetingListFilter: React.FC<{
  isCurrent?: boolean;
}> = ({ isCurrent = false }) => {
  const filter = useSelector((state: RootState) => {
    return state.meetingsFilter.value[isCurrent ? "currentMeetings" : "pastMeetings"];
  });
  const dispatch = useDispatch();
  return (
    <>
      <TitleAndSearch
        q={filter.search}
        title={"Horse Racing Form Guide | All"}
        placeholder="Search form, race or etc"
        setQ={({ target }) =>
          debounce(
            () =>
              dispatch(
                setFilter({
                  key: isCurrent ? "currentMeetings" : "pastMeetings",
                  value: { ...filter, search: target.value },
                })
              ),
            800
          )()
        }
      />
      <div className="flex justify-between my-6 text-xs flex-wrap md:flex-nowrap">
        <div className="flex-none flex items-center gap-2.5 font-normal">
          <p>Show</p>
          <select
            name="entries"
            className="form-select w-16"
            value={filter.limit}
            onChange={({ target }) =>
              dispatch(
                setFilter({
                  key: isCurrent ? "currentMeetings" : "pastMeetings",
                  value: { ...filter, limit: parseInt(target.value), page: 1 },
                })
              )
            }
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <p>entries</p>
        </div>
        <div className="grow md:flex-none flex items-center gap-2.5 font-normal mt-5 md:mt-0 flex-wrap md:flex-nowrap">
          {/* <div className="grow md:flex-none flex items-center gap-2.5">
            <p className="whitespace-nowrap hidden md:block">Date Range:</p>
            <input
              type="date"
              className="form-input"
              defaultValue={startDate}
              max={yesterday.toLocaleString("en-us")}
              onChange={({ target }) =>
                updateFilter((prev) => ({ ...prev, startDate: target.value }))
              }
            />
            <p className="shrink-0">to</p>
            <input
              type="date"
              defaultValue={endDate}
              className="form-input"
              max={new Date().toLocaleString("en-us")}
              onChange={({ target }) =>
                updateFilter((prev) => ({ ...prev, endDate: target.value }))
              }
            />
          </div> */}
          <div className="grow md:flex-none flex items-center gap-2.5">
            <p className="whitespace-nowrap hidden md:block">Date:</p>
            <input
              type="date"
              defaultValue={filter.startDate}
              className="form-input"
              max={new Date().toLocaleString("en-us")}
              onChange={({ target }) =>
                dispatch(
                  setFilter({
                    key: isCurrent ? "currentMeetings" : "pastMeetings",
                    value: { ...filter, endDate: target.value, startDate: target.value },
                  })
                )
              }
            />
          </div>
          <div className="grow md:flex-none flex items-center justify-center gap-2.5">
            <p className="whitespace-nowrap">Sort By:</p>
            <select
              name="entries"
              className="form-select w-32"
              value={filter.sortType}
              onChange={({ target }) =>
                dispatch(
                  setFilter({
                    key: isCurrent ? "currentMeetings" : "pastMeetings",
                    value: { ...filter, sortType: target.value as TSortType },
                  })
                )
              }
            >
              <option value="">Most recent</option>
              <option value="ASC">A-Z</option>
              <option value="DESC">Z-A</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingListFilter;
