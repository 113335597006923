import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { IGridSort } from "../../hooks/hook.types";
import useGridSort from "../../hooks/useGridSort";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { CurrentRaceInfoTableCols, PastRaceInfoTableCols } from "../../lib/table.columns";
import { RaceInfoEntity, SortFieldMap } from "../../types";
import { OverlayLoader } from "../Loader";

const InitialState: IGridSort = { field: "tabNo", sort: "asc" };

interface Props {
  isCurrent: boolean;
}

const RaceInfoTable: React.FC<Props> = ({ isCurrent }) => {
  const { mid, raceId } = useParams();
  const [sortObj, handleSortModelChange, sortModel] = useGridSort(InitialState);

  const { cols, right_cols } = isCurrent ? CurrentRaceInfoTableCols : PastRaceInfoTableCols;

  const URL = useMemo(() => {
    return _getURL(mid, raceId, sortObj, isCurrent);
  }, [mid, raceId, sortObj, isCurrent]);

  const { data, error } = useSWR(URL, fetcher<RaceInfoEntity[]>, {
    revalidateOnFocus: false,
  });

  const widthOfSecondTable = useMemo(() => {
    const colsObj = isCurrent ? CurrentRaceInfoTableCols : PastRaceInfoTableCols;
    return colsObj.right_cols.reduce((val, { width = 0 }) => val + parseInt(String(width)), 5);
  }, [isCurrent]);

  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);
  if (mid === undefined || raceId === undefined) return null;

  return (
    <Box className={"mb-10 flex w-full"}>
      {isLoading ? <OverlayLoader /> : null}
      <DataGrid
        rows={data === undefined ? [] : data}
        columns={cols}
        classes={{ columnHeaders: "bg-white-f9 border-x-8 border-transparent" }}
        className="!rounded-r-none"
        // loading={isLoading}
        error={error}
        sortingMode={isCurrent ? "client" : "server"}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
        hideFooter
        autoHeight
        getRowId={(row: RaceInfoEntity) => `rinfo-${row.tabNo}-${row.position}-${row.horse_id}`}
      />
      <DataGrid
        rows={data === undefined ? [] : data}
        columns={right_cols}
        hideFooter
        autoHeight
        error={error}
        // loading={isLoading}
        sortingMode={isCurrent ? "client" : "server"}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
        classes={{
          columnHeaders: "bg-white-f9 border-x-8 border-transparent",
        }}
        style={{ maxWidth: widthOfSecondTable }}
        className="!border-l-0 !rounded-l-none"
        getRowId={(row: RaceInfoEntity) => `${row.tabNo}-${row.position}-${row.horse_id}`}
      />
    </Box>
  );
};

export default RaceInfoTable;

const _getURL = (
  mid: string | undefined,
  raceId: string | undefined,
  sortObj: IGridSort,
  isCurrent: boolean
): any => {
  console.log("KEY---->>>", sortObj.field);

  let url = getURL(`/race${isCurrent ? "/future/" : "/"}${raceId}/meeting/${mid}/raceInformation`);
  if (sortObj.field.length > 0 && sortObj.sort.length > 0) {
    const field = sortFieldMap(isCurrent)[sortObj.field];
    if (field !== undefined) url = `${url}?sortFiled=${field}&sortType=${sortObj.sort}`;
  }
  return url;
};

const sortFieldMap = (isCurrent = false): { [key: string]: string } => {  
  const obj: SortFieldMap<RaceInfoEntity> = {
    tabNo: isCurrent ? "rsb2.csv_tab_no" : undefined,
    raceId: isCurrent ? "future_races.id" : "races.id",
    race_no: isCurrent ? "future_races.race_no" : "races.race_no",
    margin: isCurrent ? "future_race_fields.margin" : "race_fields.margin",
    horsesName: isCurrent ? "horses.name" : "horses.name",
    barrier: isCurrent ? "future_race_fields.barrier" : "race_fields.barrier",
    trainer: isCurrent ? "trainers.name" : "trainers.name",
    jockey: isCurrent ? "jockeys.name" : "jockeys.name",
    weight: isCurrent ? "future_race_fields.weight" : "race_fields.weight",
    age: isCurrent ? "future_race_fields.age" : "race_fields.age",
    horseSex: isCurrent ? "future_race_fields.horse_sex" : "race_fields.horse_sex",
    in_run: isCurrent ? "future_race_fields.in_run" : "race_fields.in_run",
    position: isCurrent ? "future_race_fields.position" : "race_fields.position",
    cl: isCurrent ? "future_race_fields.jock_claim" : "race_fields.jock_claim",
    trRat: isCurrent ? "future_data_generated_table.trainer_rating" : undefined,
    jkRat: isCurrent ? "future_data_generated_table.jockey_rating" : undefined,
    prep: isCurrent ? "future_data_generated_table.stage_of_prep" : undefined,
    dslr: isCurrent ? "future_data_generated_table.days_since_last_run" : undefined,
    todo: isCurrent ? "pfg.todo" : undefined,
    priceModel: isCurrent ? "future_data_generated_table.price_model" : undefined,
  };
  return obj;
};
