import { ReactElement, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../components/bread-crumb";
import Navbar from "../components/Navbar";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const RootLayout = ({ children }: Props): ReactElement => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Navbar />
      <Breadcrumbs />
      {children}
    </>
  );
};

export default RootLayout;
