import { ReactElement } from "react";

interface PaperTileProps {
  children: JSX.Element | JSX.Element[];
  titleText: string | null;
  titleClassName?: string;
  className?: string;
}

function PaperTile({
  children,
  titleText = "",
  className = "",
  titleClassName = "",
}: PaperTileProps): ReactElement {
  return (
    <>
      <h2 className={`text-secondary font-bold text-2xl ${titleClassName}`}>{titleText}</h2>
      <div className={`bg-white-f9 py-3 px-6 md:py-6 md:px-10 my-5 ${className}`}>{children}</div>
    </>
  );
}

export default PaperTile;
