import { ReactElement, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { OverlayLoader } from "../components/Loader";
import fetcher from "../lib/fetcher";
import getURL from "../lib/getEndPoint";
import NotFound from "../pages/404";
import { MeetingEntity } from "../types";
import RaceLayout from "./RaceLayout";

const STATIC_TAB = [{ to: "meeting-info", header: "Meeting Info" }];

export const CurrentRaceLayout = (): ReactElement | null => {
  const { mid, raceId } = useParams();
  const URL = useMemo(() => getURL(`/meeting/future/detail/${mid}`), [mid]);
  const { data, error } = useSWR(URL, fetcher<MeetingEntity>, { revalidateOnFocus: false });
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);

  if (mid === undefined) return <NotFound />;
  if (error !== undefined) return null;
  if (isLoading) return <OverlayLoader />;

  return (
    <RaceLayout
      data={data}
      mid={mid}
      raceId={raceId}
      basePath="/current-form-guides"
      STATIC_TAB={STATIC_TAB}
    />
  );
};
