import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { RacesEntity } from "../types";

interface RaceItemProps {
  item: RacesEntity;
  href: string;
}

export default function RaceItem({ item, href }: RaceItemProps): ReactElement {
  return (
    <li className="flex gap-2 xl:gap-4 items-start">
      <span className="bg-[#bec8d0] rounded-full py-1.5 px-2.5 xl:px-4 inline-block text-white text-sm xl:text-base !leading-none">
        {`R${item.race_no != null ? item.race_no : ""}`}
      </span>
      <Link to={href}>
        <h4 className="text-[15px] mb-1 text-primary font-bold">{item.race_name}</h4>
        <p className="text-[#828282] text-xs">
          {item.start_time} | {item.distance} | {item.total_price_amount}
        </p>
      </Link>
      <Link to={href}>
        <button className="text-primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            className="w-3.5 h-3.5 xl:w-5 xl:h-5"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </button>
      </Link>
    </li>
  );
}
