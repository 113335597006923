import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableOption } from "../types/table";

interface DynamicTable {
  currentTable: TableOption;
  tableOptions: TableOption[];
}

interface DynamicTableSlice {
  past: DynamicTable;
  current: DynamicTable;
}

interface ChangeCurrentTable {
  key: keyof DynamicTableSlice;
  currentTable: TableOption;
}

const initialState: DynamicTableSlice = {
  past: {
    currentTable: { value: "raceInformation", label: "Race Information" },
    tableOptions: [
      { value: "raceInformation", label: "Race Information" },
      { value: "extraInformation", label: "Extra Information" },
      { value: "individualTime", label: "Individual Time" },
    ],
  },
  current: {
    currentTable: { value: "raceInformation", label: "Race Information" },
    tableOptions: [
      { value: "raceInformation", label: "Race Information" },
      // { value: "speedMap", label: "Speed Map" },
      { value: "raceRating", label: "Rating" },
      { value: "otherInformation", label: "Other Information" },
    ],
  },
};

export const dynamicTableSlice = createSlice({
  name: "dynamicTable",
  initialState,
  reducers: {
    changeCurrentTable: (state, action: PayloadAction<ChangeCurrentTable>) => {
      state[action.payload.key].currentTable = action.payload.currentTable;
    },
    resetCurrentTable: (state, action: PayloadAction<"past" | "current">) => {
      state[action.payload].currentTable = { value: "raceInformation", label: "Race Information" };
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCurrentTable, resetCurrentTable } = dynamicTableSlice.actions;

export default dynamicTableSlice.reducer;
