import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { IGridSort } from "../../hooks/hook.types";
import useGridSort from "../../hooks/useGridSort";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { IndividualTimeTableCols } from "../../lib/table.columns";
import { IndividualTimeEntity as ITEntity, SortFieldMap } from "../../types";

const InitialState: IGridSort = { field: "position", sort: "asc" };
const widthOfSecondTable = IndividualTimeTableCols.right_cols.reduce(
  (val, { width = 0 }) => val + parseInt(String(width)),
  5
);

const IndividualTime: React.FC = () => {
  const { mid, raceId } = useParams();
  const [sortObj, handleSortModelChange, sortModel] = useGridSort(InitialState);
  const URL = useMemo(() => _getURL(mid, raceId, sortObj), [mid, raceId, sortObj]);
  const { data, error } = useSWR(URL, fetcher<ITEntity[]>, { revalidateOnFocus: false });
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);

  if (mid === undefined || raceId === undefined) return null;
  return (
    <Box className={"mb-10 flex w-full"}>
      <DataGrid
        classes={{ columnHeaders: "bg-white-f9 border-x-8 border-transparent" }}
        className="!rounded-r-none"
        rows={data === undefined ? [] : data}
        columns={IndividualTimeTableCols.cols}
        loading={isLoading}
        error={error}
        hideFooter
        autoHeight
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        getRowId={(row: ITEntity) => `itime-${row.position}-${row.horse_id}`}
        disableSelectionOnClick
      />
      <DataGrid
        rows={data === undefined ? [] : data}
        columns={IndividualTimeTableCols.right_cols}
        loading={isLoading}
        hideFooter
        autoHeight
        classes={{
          columnHeaders: "bg-white-f9 border-x-8 border-transparent",
        }}
        style={{ maxWidth: widthOfSecondTable }}
        className="!border-l-0 !rounded-l-none"
        getRowId={(row: ITEntity) => `${row.position}-${row.horse_id}`}
      />
    </Box>
  );
};

export default IndividualTime;

const _getURL = (
  mid: string | undefined,
  raceId: string | undefined,
  sortObj: IGridSort
): string => {
  let url = getURL(`/race/${raceId}/meeting/${mid}/individualTime`);
  if (sortObj.field.length > 0 && sortObj.sort.length > 0) {
    const field = sortFieldMap()[sortObj.field];
    if (field !== undefined) url = `${url}?sortFiled=${field}&sortType=${sortObj.sort}`;
  }
  return url;
};

const sortFieldMap = (): { [key: string]: string } => {
  const obj: SortFieldMap<ITEntity> = {
    position: "race_fields.position",
    margin: "race_fields.margin",
    hourseName: "horses.name",
    to600: "rsb.itime_to_600",
    l800: "rsb.f800_time",
    l600: "rsb.f600_time",
    l400: "rsb.f400_time",
    l200: "rsb.f200_time",
    time: "rsb.itime_to_fin",
    to_600_all: "rsb.to_600_all",
    last_600_all: "rsb.last_600_all",
    finish_all: "rsb.finish_all",
    to_600_class: "rsb.to_600_class",
    last_600_class: "rsb.last_600_class",
    finish_class: "rsb.finish_class",
  };
  return obj;
};
