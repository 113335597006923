/**
 * A function used to return API_ENDPOINT.
 * It uses env variable
 */
const getURL = (path: string): string => {
  if (
    process.env.REACT_APP_API_ENDPOINT === "" ||
    process.env.REACT_APP_API_ENDPOINT == null ||
    process.env.REACT_APP_API_ENDPOINT === undefined
  ) {
    throw new Error("REACT_APP_API_ENDPOINT env is must");
  }
  return process.env.REACT_APP_API_ENDPOINT + "/api/v1" + path;
};

export default getURL;
