import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function CustomEditComponent({
  onSubmit,
  mutate,
  ...rest
}: GridRenderEditCellParams & {
  onSubmit: (params: { row: any; field: string; value: any }) => Promise<any>;
  mutate: (params: { row: any; field: string; value: any }) => Promise<any>;
}): ReactElement {
  const { value: valueProp, field, row } = rest;
  const [value, setValue] = useState(valueProp);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  useEffect(() => {
    if (value != null || value !== undefined) return;
    setValue(valueProp);
  }, [valueProp]);

  const updateAPI = (): void => {
    if (valueProp === value) return;
    onSubmit({ row, field, value })
      .then((res) => {
        if (res.status === 200) {
          void mutate({ row, field, value });
        }
      })
      .catch((err) => {
        let message = "Failed to save";
        if (err?.response?.data?.message?.length > 0) {
          message = err.response.data.message;
        } else {
          message = err.message;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <input
      autoFocus
      className="w-full h-full px-4"
      type="text"
      value={value}
      onBlur={() => {
        void updateAPI();
      }}
      onKeyDown={(event) => {
        if (event.key !== "Enter" && event.key !== "Tab") return;
        void updateAPI();
      }}
      onChange={handleChange}
    />
  );
}
