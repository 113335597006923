import { Box } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { IGridSort } from "../../hooks/hook.types";
import useGridSort from "../../hooks/useGridSort";
import saveVideoComment from "../../lib/api/saveVideoComment";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { ExtraInformationTableCols } from "../../lib/table.columns";
import { ExtraInformationEntity, SortFieldMap } from "../../types";
import CustomEditComponent from "../helper/CustomEditComponent";

const classes = {
  columnHeaders: "bg-white-f9 border-x-8 border-transparent",
};

const InitialState: IGridSort = { field: "position", sort: "asc" };
const { cols, right_cols } = ExtraInformationTableCols;
const widthOfSecondTable = right_cols.reduce(
  (val, { width = 0 }) => val + parseInt(String(width)),
  5
);

const ExtraInformation: React.FC = () => {
  const { mid, raceId } = useParams();
  const [sortObj, handleSortModelChange, sortModel] = useGridSort(InitialState);
  const URL = useMemo(() => _getURL(mid, raceId, sortObj), [mid, raceId, sortObj]);
  const {
    data,
    error,
    mutate: mutateExtraInfo,
  } = useSWR(URL, fetcher<ExtraInformationEntity[]>, {
    revalidateOnFocus: false,
  });
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);

  const columns: GridColumns<any> = useMemo(() => {
    return [
      ...cols,
      {
        field: "videoComments",
        headerName: "Video Comments",
        width: 250,
        editable: true,
        renderEditCell(params) {
          return (
            <CustomEditComponent
              {...params}
              onSubmit={saveVideoComment}
              mutate={async ({ row, field, value }) => {
                return await mutateExtraInfo((data) => {
                  if (data === undefined) return undefined;
                  return data.map((item) =>
                    item.horse_id === row.horse_id ? { ...item, [field]: value } : item
                  );
                }, false);
              }}
            />
          );
        },
      },
    ];
  }, []);

  if (mid === undefined || raceId === undefined) return null;
  return (
    <Box className={"mb-10 flex w-full"}>
      <DataGrid
        classes={classes}
        className="!rounded-r-none"
        columns={columns}
        rows={data !== undefined ? data : []}
        loading={isLoading}
        error={error}
        autoHeight
        hideFooter
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableIgnoreModificationsIfProcessingProps
        getRowId={(row: ExtraInformationEntity) => `${row.raceId}-${row.horse_id}`}
      />
      <DataGrid
        rows={data === undefined ? [] : data}
        columns={right_cols}
        loading={isLoading}
        hideFooter
        autoHeight
        classes={{
          columnHeaders: "bg-white-f9 border-x-8 border-transparent",
        }}
        style={{ maxWidth: widthOfSecondTable }}
        className="!border-l-0 !rounded-l-none"
        getRowId={(row: ExtraInformationEntity) => `${row.raceId}-${row.horse_id}`}
      />
    </Box>
  );
};

export default ExtraInformation;

const _getURL = (
  mid: string | undefined,
  raceId: string | undefined,
  sortObj: IGridSort
): string => {
  let url = getURL(`/race/${raceId}/meeting/${mid}/extraInformation`);
  if (sortObj.field.length > 0 && sortObj.sort.length > 0) {
    const field = sortFieldMap()[sortObj.field];
    if (field !== undefined) url = `${url}?sortFiled=${field}&sortType=${sortObj.sort}`;
  }
  return url;
};

const sortFieldMap = (): { [key: string]: string } => {
  const obj: SortFieldMap<ExtraInformationEntity> = {
    race_no: "races.race_no",
    margin: "race_fields.margin",
    horsesName: "horses.name",
    gearCharges: "race_fields.gear",
    position: "race_fields.position",
    stewardsReport: "race_fields.stewards",
  };
  return obj;
};
