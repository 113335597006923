import { createSlice } from "@reduxjs/toolkit";

export type TSortType = "ASC" | "DESC" | "";

export interface FilterObject {
  limit?: number;
  page?: number;
  startDate?: string;
  endDate?: string;
  sortType?: TSortType;
  search: string;
}

const InitState: FilterObject = {
  limit: 10,
  page: 1,
  sortType: "",
  startDate: "",
  endDate: "",
  search: "",
};

export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    value: {
      pastMeetings: InitState,
      currentMeetings: InitState,
    },
  },
  reducers: {
    setFilter: (
      state,
      action: {
        payload: {
          key: "pastMeetings" | "currentMeetings";
          value: FilterObject;
        };
      }
    ) => {
      state.value[action.payload.key] = action.payload.value;
    },
  },
});

export const { setFilter } = filterSlice.actions;
