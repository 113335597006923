import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { changeCurrentTable } from "../../store/dynamicTable.slice";
import { TableOption } from "../../types/table";
import ExtraInformation from "../table/ExtraInformationTable";
import IndividualTime from "../table/IndividualTimeTable";
import RaceInfoTable from "../table/RaceInfoTable";
import SpeedMapTable from "../table/SpeedMapTable";
import RaceRating from "../table/RaceRating";
import OtherInformation from "./OtherInformation";

interface Props {
  fromRoute: "past" | "current";
}

function DynamicTableRender({ fromRoute }: Props): ReactElement | null {
  const dispatch = useDispatch();

  const tableOptions = useSelector(
    (state: RootState) => state.dynamicTable[fromRoute].tableOptions
  );

  const currentTable = useSelector(
    (state: RootState) => state.dynamicTable[fromRoute].currentTable
  );

  return (
    <>
      <div className="flex justify-between flex-wrap-reverse items-center gap-4 mb-5 whitespace-nowrap">
        <h2 className="text-secondary text-lg md:text-xl font-bold">{currentTable?.label}</h2>
        <div className="flex justify-between items-center">
          <p className="mr-3.5">Table display: </p>
          <Box>
            <FormControl sx={{ minWidth: 180 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                classes={{
                  select: "!py-2 !text-sm font-Quicksand",
                }}
                className="!rounded-xl"
                value={currentTable != null ? currentTable.value : ""}
              >
                {tableOptions.map((opt) => (
                  <MenuItem
                    key={opt?.value}
                    value={opt?.value}
                    onClick={() =>
                      dispatch(changeCurrentTable({ key: fromRoute, currentTable: opt }))
                    }
                  >
                    {opt?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
      <div style={{ minHeight: 300 }}>{renderTable({ currentTable, fromRoute })}</div>
    </>
  );
}

export default DynamicTableRender;

interface RenderTableProps {
  currentTable: TableOption | null;
  fromRoute: "past" | "current";
}

const renderTable = (args: RenderTableProps): ReactElement | null => {
  const { currentTable, fromRoute } = args;

  if (currentTable == null) return null;
  switch (currentTable.value) {
    case "speedMap":
      return <SpeedMapTable />;
    case "extraInformation":
      return <ExtraInformation />;
    case "individualTime":
      return <IndividualTime />;

    case "raceInformation":
      return <RaceInfoTable isCurrent={fromRoute === "current"} />;
    case "raceRating":
      return <RaceRating />;
    case "otherInformation":
      return <OtherInformation />;
    default:
      return (
        <Box sx={{ height: 400, width: "100%" }}>
          <div className="rounded w-full h-32  border flex justify-center items-center">
            Work In Progress - UI Is Pending
          </div>
        </Box>
      );
  }
};
