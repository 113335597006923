import { ReactElement, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { OverlayLoader } from "../../components/Loader";
import Title from "../../components/Title";
import TrackFilter from "../../components/track/TrackFilter";
import SettlingPositionTable from "../../components/trainer-jockey/SettlingPositionTable";
import { SettlingPositionEntity } from "../../components/trainer-jockey/type";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { SettlingPositionCols } from "../../lib/table.columns";
import NotFound from "../404";

export interface TrackFilterType {
  startDate?: string;
  endDate?: string;
  startDistance?: string;
  endDistance?: string;
  rail?: string;
}

const defaultState = {
  startDate: "",
  endDate: "",
  startDistance: "",
  endDistance: "",
  rail: "",
};

const cols1 = [...SettlingPositionCols];
const cols2 = [...SettlingPositionCols];

cols2.splice(0, 1, {
  key: "position",
  header: "Barrier Position",
});

interface Response {
  settlingPosition: SettlingPositionEntity[];
  barrier: SettlingPositionEntity[];
}

const TrackDetailsPage = (): ReactElement | null => {
  const { trackId } = useParams();
  const [filter, setFilter] = useState<TrackFilterType>(defaultState);
  const URL = useMemo(
    () => getURL(`/track/${trackId}/detail`) + _getURL(filter),
    [Object.values(filter), trackId]
  );
  const { data, error } = useSWR(URL, fetcher<Response>, { revalidateOnFocus: false });
  const isLoading = data === undefined && error === undefined;

  const applyFilter = (filterState: TrackFilterType): void => {
    setFilter(filterState);
  };

  if (trackId == null || trackId === undefined) return <NotFound />;

  return (
    <main className="container py-5">
      {isLoading ? <OverlayLoader /> : null}
      <h1 className="sr-only">{trackId}</h1>
      <TrackFilter applyFilter={applyFilter} />
      <SettlingPositionTable columns={cols1} data={data?.settlingPosition} />
      <Title titleText="Barrier" className="!text-xl text-secondary" />
      <SettlingPositionTable columns={cols2} data={data?.barrier} />
    </main>
  );
};

export default TrackDetailsPage;

const _getURL = ({
  startDate = "",
  endDate = "",
  startDistance = "",
  endDistance = "",
  rail = "",
}: TrackFilterType): string => {
  let url = "";
  if (startDate !== "" && endDate !== "") {
    url = `${url}startDate=${startDate}&endDate=${endDate}`;
  }
  if (startDistance !== "" && endDistance !== "") {
    url = url !== "" ? `${url}&` : '';
    url = `${url}startDist=${startDistance}&endDist=${endDistance}`;
  }
  if (rail !== "") {
    url = url !== "" ? `${url}&` : '';
    url = `${url}rail=${encodeURIComponent(rail)}`;
  }
  url = url.length > 0 ? "?" + url : url;
  return url;
};
