export const getRaceIdFromSlug = (slug: string | undefined): number => {
  if (slug === undefined || slug == null) return NaN;
  let raceId = NaN;
  try {
    const _arr = slug?.split("-");
    raceId = parseInt(_arr[_arr.length - 1]);
  } catch {
    raceId = NaN;
  }
  return raceId;
};

export const handleIfDataIsNull = (data: any, ifNull: any = ""): any => {
  try {
    if (data === null) return ifNull;
    if (data === "") return ifNull;
    if (data.length === 0) return ifNull;
    return data;
  } catch (error) {
    return data;
  }
};
