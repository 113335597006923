import { ReactElement, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Chip from "../components/Chip";
import TabButton from "../components/TabButton";
import Title from "../components/Title";
import { formatDate } from "../lib/date/formatDate";
import { setRaceInfoMeetingObj, setRaceInfoRaceObj } from "../store/breadCrumb.slice";
import { MeetingEntity } from "../types";

interface Props {
  mid: string;
  raceId: string | undefined;
  data: MeetingEntity | undefined;
  basePath: string;
  STATIC_TAB: Array<{ to: string; header: string }>;
}

const RaceLayout = ({ mid, raceId, data, basePath, STATIC_TAB }: Props): ReactElement => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const raceIdSlugName = useMemo(() => {
    if (pathname.includes("meeting-info")) return "Meeting Info";
    if (pathname.includes("meeting-summary")) return "Meeting Summary";
    if (raceId === undefined || data === undefined) return "";
    const obj = data.races.find((race) => String(race.raceId) === String(raceId));
    return obj?.race_no != null ? `Race ${obj.race_no}` : "";
  }, [mid, raceId, data, pathname]);

  useEffect(() => {
    if (data === undefined || raceId === undefined) return;
    dispatch(setRaceInfoRaceObj({ id: raceId, name: raceIdSlugName }));
  }, [raceIdSlugName, raceId, data]);

  useEffect(() => {
    if (data === undefined || mid === undefined) return;
    dispatch(setRaceInfoMeetingObj({ id: mid, name: `${data.track_abbrev} Form Guide` }));
  }, [data, mid]);

  return (
    <main className="container">
      <div className="flex mt-6 mb-4 pb-4 space-x-3 whitespace-nowrap md:space-x-5 overflow-x-auto uppercase">
        {STATIC_TAB.map((tab) => (
          <NavLink key={tab.header} to={`${basePath}/${mid}/${tab.to}`}>
            {({ isActive }) => <TabButton btnText={tab.header} isActive={isActive} />}
          </NavLink>
        ))}

        {data?.races.map(({ raceId, race_no }) => (
          <NavLink to={`${basePath}/${mid}/${raceId}`} key={raceId}>
            {({ isActive }) => <TabButton btnText={`RACE ${race_no}`} isActive={isActive} />}
          </NavLink>
        ))}
      </div>
      <div className={`${mid?.length > 0 ? "flex" : "hidden"} items-center mb-5 flex-wrap gap-3`}>
        <Title
          variant={"h3"}
          className="capitalize"
          titleText={`${
            data?.track_abbrev != null ? data.track_abbrev : ""
          } Form Guide | ${raceIdSlugName}`}
        />
        <Chip
          chipText={data?.meeting_date != null ? formatDate(data.meeting_date) : "-- -- ----"}
        />
      </div>
      <Outlet />
    </main>
  );
};

export default RaceLayout;
