import React, { useMemo, useState } from "react";
import useSWR from "swr";
import { OverlayLoader } from "../../components/Loader";
import ListItem from "../../components/NameListItem";
import TitleAndSearch from "../../components/TitleAndSearch";
import debounce from "../../lib/debounce";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { GroupChildEntitiy } from "../../types";

const Trainers: React.FC = () => {
  const [search, setSearch] = useState("");
  const URL = useMemo(() => getURL(`/trainer/list`), []);
  const { data, error } = useSWR(URL, fetcher<GroupChildEntitiy[]>, { revalidateOnFocus: false });

  const filterData = useMemo(() => {
    if (search.length < 1) return data;
    return data
      ?.map((item) => {
        const childs = item.children?.filter((_item) =>
          _item.name.toLowerCase().includes(search.toLowerCase())
        );
        return { ...item, children: childs };
      })
      .filter(
        (item) => item.children !== undefined && item.children != null && item.children.length > 0
      );
  }, [search, data]);

  if (error !== undefined) return null;

  const isLoading = data === undefined && error === undefined;

  return (
    <main className={`container py-4`}>
      {isLoading ? <OverlayLoader /> : null}
      <TitleAndSearch
        title="Trainer"
        placeholder="Search trainers"
        q={search}
        setQ={({ target }) => debounce(() => setSearch(target.value), 800)()}
      />

      {filterData?.map((item) => (
        <div key={item.group} className="mb-4">
          <p className="font-bold text-[25px] text-secondary">{item.group}</p>
          <span className="block h-[1px] my-3 bg-[#E0E7ED]" />
          <ListItem childrens={item.children} route="trainers" size={50} />
        </div>
      ))}
    </main>
  );
};

export default Trainers;
