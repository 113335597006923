import React, { Fragment, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { setHorseSlugName } from "../../store/breadCrumb.slice";
import { HorseProfileOverviewEntity } from "../../types";

interface Props {
  hid: string;
  setLoadingCount?: React.Dispatch<React.SetStateAction<number>>;
}

interface HorseProfileOverviewCol {
  title: string | null;
  rowKeys: Array<{
    title: string;
    key?: keyof HorseProfileOverviewEntity;
    value?: string;
  }>;
}

const HorseProfileOverview: React.FC<Props> = ({ hid, setLoadingCount }) => {
  const dispatch = useDispatch();
  const URL = getURL(`/horse/${hid}/profile`);
  const { data, error } = useSWR(URL, fetcher<HorseProfileOverviewEntity>, {
    revalidateOnFocus: false,
  });

  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);

  useEffect(() => {
    if (isLoading) setLoadingCount?.((prev: number) => prev + 1);
    if (!isLoading) setLoadingCount?.((prev: number) => prev - 1);
    if (data?.name != null) {
      dispatch(setHorseSlugName({ id: hid, name: data?.name }));
    }
  }, [data, isLoading]);

  if (hid === undefined) return null;

  return (
    <>
      <div className="flex items-center justify-start gap-4">
        {/* <img alt="" src={""} height={50} width={50} /> */}
        <h2 className={`text-secondary font-bold text-2xl `}>{data?.name}</h2>
      </div>
      <div className="bg-white-f9 py-6 px-10 my-5 gap-5 flex flex-wrap">
        {horseProfileOverviewCols.map((item, index) => (
          <div key={index} className="flex-auto">
            <h3 className="text-secondary capitalize text-[17px] font-bold mb-2.5">
              {item.title ?? <span>&nbsp;</span>}
            </h3>
            <ul>
              {item?.rowKeys?.map((obj, idx) => (
                <li key={`${index}-${idx}`} className="text-base font-medium">
                  <span className="w-28">
                    <strong>{obj.title}: </strong>
                  </span>
                  {obj.key === "trainers" ? (
                    <span className="inline-flex space-x-1 items-center">
                      {data?.[obj.key ?? "default"]?.map((item, _idx) => (
                        <Fragment key={`${item.id}-${_idx}`}>
                          {_idx > 0 && <span className="text-base font-medium"> & </span>}
                          <Link to={`/trainers/${item.id}`}>{item.name}</Link>
                        </Fragment>
                      ))}
                    </span>
                  ) : (
                    data?.[obj.key ?? "default"] ?? obj.value
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

const horseProfileOverviewCols: HorseProfileOverviewCol[] = [
  {
    title: null,
    rowKeys: [
      { title: "Name", key: "name" },
      {
        title: "Trainer",
        key: "trainers",
      },
      { title: "Age", key: "age" },
      { title: "sex", key: "sex" },
      { title: "Sire", key: "sire" },
      { title: "Dam", key: "dam" },
    ],
  },
  {
    title: "Career",
    rowKeys: [
      { title: "Overall", key: "overAllCount" },
      { title: "Win%", key: "winRecord" },
      { title: "Place %", key: "placeRecord" },
      { title: "Prize Money", key: "winPrice" },
      { title: "Average Prize", key: "averagePrice" },
      { title: "Ave. Settling Position", key: "settlingPosition" },
    ],
  },
  {
    title: "Track Condition",
    rowKeys: [
      { title: "Firm", key: "Firm" },
      { title: "Good", key: "Good" },
      { title: "Soft", key: "Soft" },
      { title: "Heavy", key: "Heavy" },
      { title: "Synthetic", key: "Synthetic" },
    ],
  },
  {
    title: "Stakes Races",
    rowKeys: [
      { title: "Group 1", key: "1" },
      { title: "Group 2", key: "2" },
      { title: "Group 3", key: "3" },
      { title: "Listed", key: "listed" },
    ],
  },
  {
    title: "DSLR",
    rowKeys: [
      { title: "0", key: "0" },
      { title: "1-5", key: "1_5" },
      { title: "6-10", key: "6_10" },
      { title: "11-20", key: "11_20" },
      { title: "21-30", key: "21_30" },
      { title: "31-60", key: "31_60" },
      { title: "61-90", key: "61_90" },
      { title: "90+", key: "90+" },
    ],
  },
];

export default HorseProfileOverview;
