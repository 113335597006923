import { useMemo } from "react";
import useSWR from "swr";
import fetcher from "../lib/fetcher";
import getURL from "../lib/getEndPoint";

function useJockey<T>(
  jid?: string,
  currentTab?: string
): { data: T | undefined; isLoading: boolean; isError: any } {
  const URL = useMemo(() => {
    if (jid !== undefined && currentTab !== undefined) {
      return getURL(`/jockey/${jid}/tabs?tab=${currentTab}`);
    }
    if (jid !== undefined) {
      return getURL(`/jockey/${jid}/detail`);
    }
    return null;
  }, [jid, currentTab]);

  const { data, error } = useSWR(URL, fetcher<T>, { revalidateOnFocus: false });

  return {
    data,
    isLoading: error === undefined && data === undefined,
    isError: error,
  };
}

export default useJockey;
