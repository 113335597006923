import { ReactElement, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import DynamicTableRender from "../../components/form-guides/DynamicTableRender";
import RaceOverview from "../../components/form-guides/RaceOverview";
import { OverlayLoader } from "../../components/Loader";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { RaceOverViewEntity } from "../../types";
import NotFound from "../404";

const CurrentRaceInfoPage = (): ReactElement | null => {
  const { mid, raceId } = useParams();
  const URL = useMemo(() => _getURL(mid, raceId), [mid, raceId]);
  const { data, error } = useSWR(URL, fetcher<RaceOverViewEntity>, { revalidateOnFocus: false });
  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);
  if (mid === undefined || raceId === undefined) return <NotFound />;

  if (error != null) return null;

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      <RaceOverview data={data} />
      <DynamicTableRender fromRoute="current" />
    </>
  );
};

export default CurrentRaceInfoPage;

const _getURL = (mid: string | undefined, raceId: string | undefined): string | null => {
  if (mid === undefined || raceId === undefined) return null;
  return getURL(`/race/future/${raceId}/meeting/${mid}/raceOverview`);
};
