import React from "react";
import Title from "./Title";

interface Props {
  title: string;
  q: string;
  setQ: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
}

const TitleAndSearch: React.FC<Props> = ({ title, q, setQ, placeholder }: Props) => {
  return (
    <div className="md:flex justify-between items-center">
      <Title
        variant={"h3"}
        className="text-lg md:text-2xl xl:text-[32px] text-primary grow font-bold mb-3 md:mb-0"
        titleText={title}
      />
      <div className="relative md:w-[250px]">
        <input
          type="text"
          className="form-input pl-8"
          placeholder={placeholder}
          defaultValue={q}
          onChange={setQ}
        />
        <button className="absolute top-3 left-2.5 text-[#bdbdbd]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-4 h-4"
          >
            <path
              fillRule="evenodd"
              d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default TitleAndSearch;
