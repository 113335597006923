import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { memo, useMemo } from "react";
import { RaceLeaderTableColumns, RaceLeadersTableColumns } from "../../lib/table.columns";
import { LeaderEntity } from "../../types";

interface RaceLeaderTablePorps {
  leaders: LeaderEntity[] | undefined;
  isLeaders?: boolean;
}

const leadersCols = [...RaceLeadersTableColumns];
leadersCols.splice(-1, 0, { key: "winner", header: "Winner" });
leadersCols.splice(1, 0, { key: "dist", header: "Distance" });

const RaceLeaderTable: React.FC<RaceLeaderTablePorps> = ({ leaders, isLeaders = false }) => {
  const columns = useMemo(() => (isLeaders ? leadersCols : RaceLeaderTableColumns), [isLeaders]);

  return (
    <>
      <h2 className="text-secondary text-lg md:text-xl font-bold mb-3.5">
        {isLeaders ? "Race Leaders" : "Race Leader"}
      </h2>
      <TableContainer
        className={`shadow-none rounded-2xl mt-5 mb-12 border border-black-light relative min-h-[100px]`}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="bg-white-f9">
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.key} sx={{ fontWeight: "bold" }}>
                  {col.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {leaders !== undefined && leaders.length > 0 ? (
              leaders.map((leader, idx) => (
                <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  {columns.map((col, index) => (
                    <TableCell key={`cell-${index}-${leader[col.key]}`}>
                      {col.key === "race_id" ? `Race ${idx + 1}` : leader[col.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td className="absolute inset-0 top-[57px] flex justify-center items-center">
                  No Records
                </td>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(RaceLeaderTable);
