import { configureStore } from "@reduxjs/toolkit";
import breadCrumbSlice from "./breadCrumb.slice";
import dynamicTableReducer from "./dynamicTable.slice";
import { filterSlice } from "./meetingsFilter.slice";

export const store = configureStore({
  reducer: {
    dynamicTable: dynamicTableReducer,
    breadCrumbs: breadCrumbSlice,
    meetingsFilter: filterSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
