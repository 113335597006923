export const pastTableOptions = [
  { value: "raceInformation", label: "Race Information" },
  { value: "extraInformation", label: "Extra Information" },
  { value: "individualTime", label: "Individual Time" },
];

export const currentTableOptions = [
  { value: "raceInformation", label: "Race Information" },
  { value: "speedMap", label: "Speed Map" },
];

export const TabOptions = [
  { label: "Overall", key: "overAll" },
  { label: "Days", key: "days" },
  { label: "Runners", key: "rides" },
  { label: "Months", key: "months" },
  { label: "Track Condition", key: "trackCondition" },
  { label: "Distance", key: "distance" },
  { label: "Weight", key: "weight" },
  { label: "State", key: "states" },
  { label: "DSLR", key: "dslr" },
  { label: "Track", key: "track" },
] as const;

export type TabOptionType = typeof TabOptions;
export type CurrentTabType = typeof TabOptions[number];
