import axios from "axios";
import { ExtraInformationEntity } from "../../types";
import getURL from "../getEndPoint";

const saveVideoComment = async (obj: {
  row: ExtraInformationEntity;
  field: string;
  value: any;
}): Promise<any> => {
  // /v1/race/{raceId}/horse/{horseId}/videoComment
  const { raceId, horse_id } = obj.row;
  const url = getURL(`/race/${raceId}/horse/${horse_id}/videoComment`);
  const res = await axios.put(url, {
    comment: obj.value,
  });
  return res;
};

export default saveVideoComment;
