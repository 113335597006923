import { memo, ReactElement } from "react";
import { RaceOverviewKeys, TotalStakesKeys } from "../../lib/table.columns";
import { RaceOverViewEntity } from "../../types";
import PaperTile from "../PaperTile";

interface RaceOverviewProps {
  data: RaceOverViewEntity | undefined;
}

function RaceOverview({ data }: RaceOverviewProps): ReactElement | null {
  if (data?.overView == null) return null;
  return (
    <PaperTile className="grid  md:grid-cols-auto3 gap-5" titleText={data.overView.race_name}>
      <div className="md:col-span-2 md:border-r border-r-[#e3e5e5] pr-6">
        <h3 className="text-secondary text-lg font-bold mb-2.5">Race Overview</h3>
        <div className="grid lg:grid-cols-auto3 gap-5">
          {RaceOverviewKeys.map(({ group }, idx) => (
            <ul key={idx}>
              {group.map(({ key, header }) => (
                <li key={key} className="text-[15px] font-medium">
                  <strong>{header}: </strong> {data?.overView[key]}
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
      <div className="planr_stakes">
        <h3 className="text-secondary text-lg font-bold mb-2.5">
          Total Stakes {data.totalStakes.total_value}
        </h3>
        <div className="grid grid-cols-auto2 gap-5 ">
          {TotalStakesKeys.map(({ group }, idx) => (
            <ul key={`li-stakes-${idx}`}>
              {group.map((key) => (
                <li key={key}>
                  <strong>{key}: </strong> {data.totalStakes[key]}
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </PaperTile>
  );
}

export default memo(RaceOverview);
