import { ReactElement, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import useJockey from "../../hooks/useJockey";
import { TrainerTabEntity } from "./type";
import CommonTab from "./CommonTab";
import { OverlayLoader } from "../Loader";

const JockeyTabs = ({ tab }: { tab: string }): ReactElement => {
  const { jid } = useParams();
  const { data, isLoading } = useJockey<TrainerTabEntity>(jid, tab);

  const getTab = useCallback(
    (k: string): any => {
      return { ...data?.tab }[k];
    },
    [data?.tab]
  );

  const outerCols = useMemo(() => {
    if (data?.tab === undefined) return null;
    const tabKeys = Object.keys(data.tab);
    if (tabKeys.length > 0) {
      if (!tabKeys.some((key) => typeof getTab(key) === "string")) {
        return tabKeys;
      }
    }
    return null;
  }, [data?.tab]);

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      <CommonTab data={data} outerCols={outerCols} getTab={getTab} currentTabKey={tab} />
    </>
  );
};

export default JockeyTabs;
