import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import PropType, { object } from "prop-types";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { getCurrentRaceInfoData } from "../../utils/constants";
import { SpeedMapRow } from "../../utils/type";

const speedMapData: SpeedMapRow[] = getCurrentRaceInfoData.speedMapData;

function SpeedMapTable(): ReactElement {
  return (
    <TableContainer
      className="shadow-none rounded-2xl mb-12 border border-black-light"
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {speedMapData?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ td: { borderRight: "1px solid #e0e7ed" }, "td:last-child": { borderRight: 0 } }}
            >
              <TableCell>
                <div className="flex justify-center items-center">
                  <svg
                    width="40"
                    height="33"
                    viewBox="0 0 40 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M40 16.5L16.5625 0.875V9.29297H0V23.707H16.5625V32.125L40 16.5Z"
                      fill="#405F9D"
                    />
                  </svg>
                </div>
              </TableCell>
              {Object.values(row).map((col: SpeedMapRow[keyof SpeedMapRow], _index) => {
                if (typeof col === "object") {
                  return (
                    <TableCell key={`${index}-${_index}`}>
                      <Link to={`/horses/${col.id}`} target="_blank">
                        <div className="flex flex-col font-semibold cursor-pointer">
                          <div className="flex items-center gap-2.5 mb-2 text-[#333333]">
                            <img alt={col?.name} src={col.src} height={30} width={30} /> (12)
                          </div>
                          <span className="text-primary ">{col?.name}</span>
                          <span className="text-[#828282]">{col?.price}</span>
                        </div>
                      </Link>
                    </TableCell>
                  );
                }
                return <TableCell key={`${index}-${_index}`}>{col}</TableCell>;
              })}
            </TableRow>
          ))}
          <TableRow
            className="bg-white-f9"
            sx={{
              td: { border: 0, borderRight: "1px solid #e0e7ed" },
              "td:last-child": { border: 0 },
            }}
          >
            <TableCell sx={{ fontWeight: "bold" }}></TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
              Backmarker
            </TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
              Midfield / Backmarker
            </TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
              Midfield
            </TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
              On Pace / Midfield
            </TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
              On Pace
            </TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
              Leader
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SpeedMapTable;

SpeedMapTable.propTypes = {
  speedMapRow: PropType.arrayOf(object),
};
