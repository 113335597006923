import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { OverlayLoader } from "../../components/Loader";
import PaperTile from "../../components/PaperTile";
import Tab from "../../components/Tab";
import SettlingPositionTable from "../../components/trainer-jockey/SettlingPositionTable";
import TrainerTabs from "../../components/trainer-jockey/TrainerTabs";
import {
  AveragePrizeMoneyEntity,
  TrainerJockeyDetailEntity,
} from "../../components/trainer-jockey/type";
import useTrainer from "../../hooks/useTrainer";
import { CurrentTabType, TabOptions } from "../../lib/constant.data";
import { SettlingPositionCols } from "../../lib/table.columns";
import { handleIfDataIsNull } from "../../lib/utils";
import { RootState } from "../../store";
import { setTrainerSlugName } from "../../store/breadCrumb.slice";
import { ColumnDefinitionType } from "../../types/table";

const PrizemoneyCols: Array<ColumnDefinitionType<AveragePrizeMoneyEntity>> = [
  { key: "Total", header: "Total" },
  { key: "90_days", header: "Last 90 Days" },
  { key: "180_days", header: "Last 180 Days" },
  { key: "365_days", header: "Last 365 Days" },
];

const columns = [...SettlingPositionCols];

columns.splice(2, 1, {
  key: "rides",
  header: "Starters",
});

function Trainer(): ReactElement {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<CurrentTabType>({
    key: "overAll",
    label: "Overall",
  });
  const { data, isLoading } = useTrainer<TrainerJockeyDetailEntity>(tid);

  const trainer = useSelector((state: RootState) => state.breadCrumbs.trainer);
  const trainerName = tid === trainer?.id ? trainer?.name : null;

  useEffect(() => {
    if (data === undefined || tid === undefined) return;
    if (data.about.name.length < 1) return;
    dispatch(setTrainerSlugName({ id: tid, name: data.about.name }));
  }, [data]);

  return (
    <main className="container">
      {isLoading ? <OverlayLoader /> : null}
      <PaperTile
        titleText={trainerName ?? "Trainer Name"}
        className="max-w-max mb-8"
        titleClassName="mt-4"
      >
        <h3 className="text-secondary text-lg font-bold mb-3.5">Average Prizemoney</h3>
        <ul>
          {PrizemoneyCols.map(({ key, header }) => (
            <li className="text-[15px] font-medium grid grid-cols-2 gap-5" key={key}>
              <span>
                <strong>{header} : </strong>
              </span>
              <span>$ {handleIfDataIsNull(data?.averagePrizeMoney[key], 0)}</span>
            </li>
          ))}
        </ul>
      </PaperTile>
      <Tab
        options={TabOptions}
        currentTab={currentTab}
        changeTab={(value) => setCurrentTab(value)}
      />
      <h2 className="text-secondary text-lg md:text-xl font-bold mt-6 mb-3.5">
        {currentTab.label} Stats
      </h2>
      <TrainerTabs tab={currentTab.key} />
      {currentTab.key === "overAll" ? (
        <>
          <h2 className="text-secondary text-lg md:text-xl font-bold mt-6 mb-3.5">
            Settling Position
          </h2>
          <SettlingPositionTable columns={columns} data={data?.settlingPosition} />
        </>
      ) : null}
    </main>
  );
}

export default Trainer;
