import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChildrenEntity } from "../types";

interface Props {
  childrens: ChildrenEntity[] | null | undefined;
  route: "horses" | "jockeys" | "trainers" | "tracks";
  size?: number;
}

const ListItem: React.FC<Props> = ({ childrens, route, size = 30 }: Props) => {
  const [sliceBy, setSliceBy] = useState(size);

  if (childrens === undefined || childrens == null) return null;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-2 whitespace-nowrap">
      {childrens.slice(0, sliceBy - 1).map((child) => (
        <p className="font-medium text-[15px] mb-1 text-primary" key={child.id}>
          {route === "tracks" ? (
            <Link to={`/${route}/${child.name}`}>{child.name}</Link>
          ) : (
            <Link to={`/${route}/${child.id}`}>{child.name}</Link>
          )}
        </p>
      ))}
      {childrens.length > sliceBy ? (
        <p
          className="cursor-pointer underline font-medium text-[15px] mb-1 text-black"
          onClick={() => setSliceBy((prev) => prev + size)}
        >
          {"View more >>"}
        </p>
      ) : null}
    </div>
  );
};

export default ListItem;
