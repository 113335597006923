import { GridSortModel } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { IGridSort } from "./hook.types";

const useGridSort = (
  initialValue: IGridSort
): [IGridSort, (sortModel: GridSortModel) => void, IGridSort[]] => {
  const [sortObj, setSortObj] = useState(initialValue);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel[0]?.sort !== undefined && sortModel[0]?.sort != null) {
      setSortObj({ field: sortModel[0]?.field, sort: sortModel[0].sort });
    }
  }, []);

  return [sortObj, handleSortModelChange, [sortObj]];
};

export default useGridSort;
