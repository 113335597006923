import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { memo, ReactElement } from "react";
import { ColumnDefinitionType } from "../../types/table";
import { SettlingPositionEntity } from "./type";

interface Props {
  data: SettlingPositionEntity[] | undefined;
  columns: Array<ColumnDefinitionType<SettlingPositionEntity>>;
}
const position_order: { [K: string]: number } = {
  Lead: 1,
  "On-Pace": 2,
  "Mid-Front": 3,
  Mid: 4,
  "Mid-Back": 5,
  Rear: 6,
};

function sortData(item1: any, item2: any): number {
  return position_order[item1.position] - position_order[item2.position];
}

const SettlingPositionTable = ({ data, columns }: Props): ReactElement => {
  if (data !== undefined && data.length > 0) {
    data?.sort(sortData);
  }

  return (
    <div className="flex mt-5 mb-12 min-h-[200px]">
      <TableContainer
        className="!shadow-none !rounded-2xl !border !border-black-light relative"
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="bg-white-f9 whitespace-nowrap">
            <TableRow>
              {columns?.map((col) => (
                <TableCell sx={{ fontWeight: "bold" }} key={col.key}>
                  {col.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data !== undefined && data.length > 0 ? (
              data.map((row, idx) => (
                <TableRow
                  key={`row-${row.position}-${idx}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map(({ key, renderCell }, _idx) => {
                    if (renderCell != null) {
                      return (
                        <TableCell key={`col-${row[key]}-${_idx}`}>
                          {renderCell(row[key])}
                        </TableCell>
                      );
                    }
                    return <TableCell key={`col-${row[key]}-${_idx}`}>{row[key]}</TableCell>;
                  })}
                </TableRow>
              ))
            ) : (
              <tr>
                <td className="absolute inset-0 top-[57px] flex justify-center items-center">
                  No Records
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default memo(SettlingPositionTable);
