import { ReactElement, useState } from "react";
import { Link, NavLink } from "react-router-dom";

const NAV_LINKS = [
  // { name: "Home", href: "/" },
  { name: "Past Form Guides", href: "/past-form-guides" },
  { name: "Current Form Guides", href: "/current-form-guides" },
  { name: "Horses", href: "/horses" },
  { name: "Trainers", href: "/trainers" },
  { name: "Jockeys", href: "/jockeys" },
  { name: "Tracks", href: "/tracks" },
];

export default function Navbar(): ReactElement {
  const [mobileNav, setMobileNav] = useState(false);

  const handleMobileNav = (): void => {
    setMobileNav(!mobileNav);
  };

  return (
    <header className="border-b-2 md:border-b-0 border-primary">
      <div className="container">
        <div className="flex items-center justify-between py-4 md:py-6">
          <Link to="/" className="flex items-center gap-2.5">
            <img src={"/logo.png"} alt={"site logo"} className="w-[50px] h-[50px]" />{" "}
            <p className="text-base md:text-lg font-bold leading-6 text-primary">
              HORSE <br />
              RACING
            </p>
          </Link>
          <div className="flex items-center gap-3">
            <Link
              to="#account"
              className="flex text-[15px] items-center gap-2.5 font-normal text-black/80 hover:text-primary"
            >
              Account{" "}
              <svg xmlns="http://www.w3.org/2000/svg" className="w-2.5 h-1.5">
                <path d="M5.016 0 0 .003 2.506 2.5 5.016 5l2.509-2.5L10.033.003 5.016 0z" />
              </svg>
            </Link>

            <div className="md:hidden mobile_icon">
              <button
                onClick={handleMobileNav}
                className="flex items-center justify-center bg-primary w-9 h-9 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="top_menu bg-primary md:py-2">
        <div className="container" style={mobileNav ? { display: "block" } : {}}>
          {mobileNav ? (
            <div className="bg-white fixed right-0 inset-y-0 w-[320px] m-auto z-50 pt-16">
              <button
                onClick={handleMobileNav}
                className="flex items-center justify-center bg-primary w-9 h-9 text-white absolute top-4 right-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <ul className="px-4 divide-y divide-black-light border-t border-black-light">
                {NAV_LINKS.map(({ name, href }) => (
                  <li onClick={handleMobileNav} key={name}>
                    <NavLink
                      to={href}
                      end={href === "/"}
                      className={({ isActive }) =>
                        isActive ? "active bg-yellow-gray text-black p-3 block" : "p-3 block"
                      }
                    >
                      {name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <ul className="hidden md:flex space-x-9 text-white text-sm">
              {NAV_LINKS.map(({ name, href }) => (
                <li key={name}>
                  <NavLink
                    to={href}
                    end={href === "/"}
                    className={({ isActive }) =>
                      isActive ? "active text-yellow-gray" : "hover:text-yellow-gray"
                    }
                  >
                    {name}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </header>
  );
}
