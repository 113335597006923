/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import { BreadcrumbComponentType } from "use-react-router-breadcrumbs";
import { RootState } from "../../store";

const HorseBreadcrumb: BreadcrumbComponentType<"hid"> = ({ match: { params } }) => {
  const horse = useSelector((state: RootState) => state.breadCrumbs.horse);
  const name = params.hid === horse?.id ? horse?.name : params.hid;
  return <span>{name}</span>;
};

export default HorseBreadcrumb;
