import { ReactElement } from "react";

interface TitleProps {
  variant?: string;
  className?: string;
  titleText: string;
}

const Title = ({ variant, titleText, className }: TitleProps): ReactElement => {
  const classNameVar = `font-bold text-primary text-xl md:text-[32px] md:leading-[48px] ${className}`;
  // <p className='text-lg'></p>
  switch (variant) {
    case "h1":
      return <h1 className={classNameVar}> {titleText} </h1>;
    case "h2":
      return <h2 className={classNameVar}> {titleText} </h2>;
    case "h3":
      return <h3 className={classNameVar}> {titleText} </h3>;
    case "h4":
      return <h4 className={classNameVar}> {titleText} </h4>;
    case "h5":
      return <h5 className={classNameVar}> {titleText} </h5>;
    case "h6":
      return <h6 className={classNameVar}> {titleText} </h6>;
    case "p":
      return <p className={classNameVar}>{titleText}</p>;
    default:
      return <h1 className={classNameVar}>{titleText}</h1>;
  }
};

export default Title;
