import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import HorseProfile from "../../components/form-guides/HorseProfile";
import NotFound from "../404";

const HorseProfilePage = (): ReactElement | null => {
  const { hid } = useParams();
  if (hid == null || hid === undefined) return <NotFound />;

  return (
    <main className="container py-5">
      <h1 className="sr-only">{hid}</h1>
      <HorseProfile hid={hid} />
    </main>
  );
};

export default HorseProfilePage;
