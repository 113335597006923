import { ReactElement, useState } from "react";
import HorseProfileFormTable from "../table/HorseProfile/FormTable";
import PeakRatingsTable from "../table/HorseProfile/PeakRatingsTable";
import SectionalTable from "../table/HorseProfile/SectionalTable";
import HorseProfileOverview from "./HorseProfileOverview";
import { OverlayLoader } from "../Loader";

export default function HorseProfile({ hid }: { hid: string }): ReactElement {
  const [loadingCount, setLoadingCount] = useState(0);

  return (
    <>
      {loadingCount > 0 && <OverlayLoader />}
      <HorseProfileOverview hid={hid} setLoadingCount={setLoadingCount} />
      <PeakRatingsTable hid={hid} setLoadingCount={setLoadingCount} />
      <HorseProfileFormTable hid={hid} setLoadingCount={setLoadingCount} />
      <SectionalTable hid={hid} setLoadingCount={setLoadingCount} />
    </>
  );
}
