import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { IGridSort } from "../../hooks/hook.types";
import useGridSort from "../../hooks/useGridSort";
import fetcher from "../../lib/fetcher";
import getURL from "../../lib/getEndPoint";
import { CurrentRaceOtherInfoTableColumns } from "../../lib/table.columns";
import { CurrentRaceOtherInfoEntity, SortFieldMap } from "../../types";
import { OverlayLoader } from "../Loader";

const InitialState: IGridSort = { field: "tabNo", sort: "asc" };

const rightTableColumns = CurrentRaceOtherInfoTableColumns.splice(-2);
const widthOfSecondTable = rightTableColumns?.reduce(
  (val, { width = 0 }) => val + parseInt(String(width)),
  5
);

const OtherInformation: React.FC = () => {
  const { mid, raceId } = useParams();
  const [sortObj, handleSortModelChange, sortModel] = useGridSort(InitialState);

  const URL = useMemo(() => {
    return _getURL(mid, raceId, sortObj);
  }, [mid, raceId, sortObj]);

  const { data, error } = useSWR(URL, fetcher<CurrentRaceOtherInfoEntity[]>, {
    revalidateOnFocus: false,
  });

  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);
  if (mid === undefined || raceId === undefined) return null;

  return (
    <Box className={"mb-10 flex w-full"}>
      {isLoading ? <OverlayLoader /> : null}
      <DataGrid
        experimentalFeatures={{ columnGrouping: true }}
        rows={data ?? []}
        columns={CurrentRaceOtherInfoTableColumns}
        classes={{ columnHeaders: "bg-white-f9 border-x-8 border-transparent" }}
        className="!rounded-r-none"
        loading={isLoading}
        error={error}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
        hideFooter
        autoHeight
        getRowId={(row: CurrentRaceOtherInfoEntity) => `table-left-info-${row.tabNo}`}
      />
      <DataGrid
        experimentalFeatures={{ columnGrouping: true }}
        rows={data ?? []}
        columns={rightTableColumns}
        hideFooter
        autoHeight
        error={error}
        loading={isLoading}
        classes={{ columnHeaders: "bg-white-f9 border-x-8 border-transparent" }}
        style={{ maxWidth: widthOfSecondTable }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
        className="!border-l-0 !rounded-l-none"
        getRowId={(row: CurrentRaceOtherInfoEntity) => `table-right-info-${row.tabNo}`}
      />
    </Box>
  );
};

export default OtherInformation;
const _getURL = (
  mid: string | undefined,
  raceId: string | undefined,
  sortObj: IGridSort
): string => {
  let url = getURL(`/race/future/${raceId}/meeting/${mid}/otherInformation`);
  if (sortObj.field.length > 0 && sortObj.sort.length > 0) {
    const field = sortFieldMap()[sortObj.field];
    if (field !== undefined) url = `${url}?sortFiled=${field}&sortType=${sortObj.sort}`;
  }
  return url;
};

const sortFieldMap = (): { [key: string]: string } => {
  const obj: SortFieldMap<CurrentRaceOtherInfoEntity> = {
    horse: "horses.name",
    tabNo: "future_race_sectional_benchmark.csv_tab_no",
    settling_position: "fdg.settling_position",
    last_to_600_class: "fdg.last_to_600_class",
    last_600_class: "fdg.last_600_class",
    second_settling_position: "fdg.second_settling_position",
    second_last_to_600_class: "fdg.second_last_to_600_class",
    second_last_600_class: "fdg.second_last_600_class",
    third_settling_position: "fdg.third_settling_position",
    third_last_to_600_class: "fdg.third_last_to_600_class",
    third_last_600_class: "fdg.third_last_600_class",
    avg_settling_position: "fdg.avg_settling_position",
    pace_blend: "fdg.pace_blend",
    pace_blend_rank: "fdg.pace_blend_rank",
    toDo: "pfg.todo",
    price_model: "fdg.price_model",
  };
  return obj;
};
