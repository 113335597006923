/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import { BreadcrumbComponentType } from "use-react-router-breadcrumbs";
import { RootState } from "../../store";

const TrainerBreadcrumb: BreadcrumbComponentType<"trainerId"> = ({ match: { params } }) => {
  const trainer = useSelector((state: RootState) => state.breadCrumbs.trainer);
  const name = params.trainerId === trainer?.id ? trainer?.name : params.trainerId;
  return <span>{name}</span>;
};

export default TrainerBreadcrumb;
