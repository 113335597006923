import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import fetcher from "../../../lib/fetcher";
import getURL from "../../../lib/getEndPoint";
import { SectionalTableColumns } from "../../../lib/table.columns";
import { SectionalTableEntity } from "../../../types";

interface Props {
  hid: string;
  setLoadingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const itemsPerPage = 10;
const TableHeadHeight = 114;
const TableHeadHeightClass = `!h-[${TableHeadHeight}px]`;

const colsLength = SectionalTableColumns.reduce((acc, col) => {
  return acc + col.childCols.length;
}, 0);

const SectionalTable: React.FC<Props> = ({ hid, setLoadingCount }) => {
  const URL = getURL(`/horse/${hid}/sectionalInfo`);
  const { data, error } = useSWR(URL, fetcher<SectionalTableEntity[]>, {
    revalidateOnFocus: false,
  });

  const isLoading = useMemo(() => data === undefined && error === undefined, [data, error]);

  useEffect(() => {
    if (isLoading) setLoadingCount?.((prev: number) => prev + 1);
    if (!isLoading) setLoadingCount?.((prev: number) => prev - 1);
  }, [isLoading]);

  if (hid === undefined) return null;

  return (
    <div className="mt-4">
      <h3 className="text-secondary font-bold text-xl mb-3.5">Sectional Information</h3>
      <RenderTable data={data ?? []} />
    </div>
  );
};

export default SectionalTable;

const RenderTable: React.FC<{ data: SectionalTableEntity[] }> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [prevScrollTop, setPrevScrollTop] = useState(0);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const { scrollLeft, scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (isLoading) return;
    if (scrollTop < prevScrollTop && scrollLeft > 0) return;

    if (
      scrollTop + clientHeight + 1 >= scrollHeight &&
      currentPage < Math.ceil(data.length / itemsPerPage)
    ) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentPage((prevState) => prevState + 1);
        setIsLoading(false);
      }, 500);
    }
    setPrevScrollTop(scrollTop);
  };

  const tableRows = useMemo(() => {
    const start = 0;
    const end = Math.min(currentPage * itemsPerPage, data.length);
    return data.slice(start, end + 1);
  }, [data, currentPage]);

  return (
    <div className="relative mt-5 mb-14">
      <TableContainer
        component={Paper}
        onScroll={handleScroll}
        style={{
          overflowY : 'auto',
          height:
            TableHeadHeight +
            (tableRows.length > itemsPerPage ? itemsPerPage : tableRows.length) * 54,
        }}
        className="shadow-none rounded-2xl border border-black-light whitespace-pre"
      >
        <Table className="!border-separate">
          <TableHead className={`bg-white-f9 sticky top-0 z-10 shadow-sm ${TableHeadHeightClass}`}>
            <TableRow>
              {SectionalTableColumns.map((col, idx) => (
                <TableCell
                  key={idx}
                  colSpan={col.childCols.length}
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    borderBottom: col.header != null ? 1 : 0,
                  }}
                  className="whitespace-normal border-l !border-black-light first:border-l-0 last:border-r-0 last:sticky last:right-0 last:z-10 bg-white-f9 !px-2.5"
                  component="th"
                >
                  {col.header}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {SectionalTableColumns.map((col) =>
                col.childCols.map((childCol, idx) => (
                  <TableCell
                    key={idx}
                    sx={{ fontWeight: "bold" }}
                    className={`whitespace-pre bg-white-f9  border-black-light !px-2.5 ${
                      childCol.className ?? ""
                    } ${idx === 0 ? "border-l  first:border-l-0" : ""}`}
                    component="th"
                  >
                    {childCol.header}
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows?.map((row, idx) =>
              row.heading?.length != null ? (
                <TableRow key={idx} className="h-[54px]">
                  <TableCell
                    align="center"
                    colSpan={colsLength}
                    className="!bg-[rgba(255,246,232,0.8)] !font-bold !text-[#ff9900] !px-2.5"
                  >
                    {row.heading}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  className="h-[54px]"
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {SectionalTableColumns.map((cols, index) =>
                    cols.childCols.map((childCol, childIdx) => {
                      const val = row[childCol.key] ?? "";
                      return (
                        <TableCell
                          key={`${childIdx}-${row[childCol.key]}`}
                          component="td"
                          sx={{
                            maxWidth: childCol?.width ?? "max-content",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            backgroundColor:
                              index === 1
                                ? parseInt(val) < 5 && parseInt(val) > 0
                                  ? "#C7EECF"
                                  : parseInt(val) > 4 && parseInt(val) < 9
                                  ? "#FEEAA0"
                                  : parseInt(val) > 8
                                  ? "#FEC7CE"
                                  : "inherit"
                                : "inherit",
                          }}
                          style={{
                            textAlign: index === 1 ? "center" : "left",
                          }}
                          className={`!border-black-light ${
                            childCol.className != null
                              ? `${childCol.className} !bg-white !px-2.5`
                              : "!px-2.5"
                          }`}
                        >
                          {childCol.renderCell != null ? childCol.renderCell(val) : val}
                        </TableCell>
                      );
                    })
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && (
        <div className="absolute top-full translate-y-3 left-0 right-0 z-50 text-center  flex justify-center">
          <p className="py-2 px-8 rounded-full bg-gray-200">Loading...</p>
        </div>
      )}
    </div>
  );
};
